import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Grid,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Tabs,
    Tab,
    useMediaQuery,
    useTheme,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    ListItemText,
    ListItem,
    List,
    Stack
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Images from '../../../utils/Images';
import { API } from '../../../utils/Api';
import toast from 'react-hot-toast';
import { WhatsApp } from '@mui/icons-material';

// TabPanel helper component for rendering tab content
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
        </div>
    );
}

function TicketsSection({ userId, tickets, isSmallScreen }) {
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [openTicketModal, setOpenTicketModal] = useState(false);
  
    const handleOpenTicket = (ticket) => {
      setSelectedTicket(ticket);
      setOpenTicketModal(true);
    };
  
    const handleCloseTicketModal = () => {
      setSelectedTicket(null);
      setOpenTicketModal(false);
    };
  
    // Mobile view: show a list with key details.
    const mobileView = (
      <Box
        sx={{
          padding: 2,
          width: '90%',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: tickets.length > 0 ? 'flex-start' : 'center',
          alignItems: 'center',
          backgroundColor: '#fff'
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 'bold', color: '#0E4961', marginBottom: 2 }}
          gutterBottom
        >
          Tickets Raised
        </Typography>
        {tickets.length > 0 ? (
          <List sx={{ width: '100%' }}>
            {tickets.map((ticket) => (
              <ListItem
                button
                key={ticket.id}
                onClick={() => handleOpenTicket(ticket)}
                sx={{
                  border: '1px solid #ddd',
                  borderRadius: 2,
                  marginBottom: 1,
                  padding: 1.5,
                  backgroundColor: '#f4f4f9',
                  boxShadow: 1,
                  transition: '0.3s',
                  '&:hover': {
                    boxShadow: 3,
                    backgroundColor: '#e8eaf6'
                  }
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#333' }}>
                      Ticket No: {ticket.ticket_no}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography component="span" variant="body2" sx={{ color: '#555' }}>
                        Subject: {ticket.subject}
                      </Typography>
                      <br />
                      <Typography
                        component="span"
                        variant="body2"
                        sx={{ color: ticket.status === 'OPEN' ? 'green' : 'red', fontWeight: 'bold' }}
                      >
                        Status: {ticket.status}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Box sx={{ textAlign: 'center', padding: 4 }}>
            <Typography variant="h6" sx={{ color: 'red' }}>
              No Tickets Raised
            </Typography>
            <Typography variant="body1" sx={{ color: '#888' }}>
              You haven't raised any tickets yet. If you need assistance, feel free to raise a ticket!
            </Typography>
          </Box>
        )}
        {/* Modal for full ticket details */}
        <Dialog open={openTicketModal} onClose={handleCloseTicketModal} fullWidth>
          <DialogTitle
            sx={{ backgroundColor: '#0E4961', color: '#fff', fontWeight: 'bold', borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
          >
            {selectedTicket ? `Ticket No: ${selectedTicket.ticket_no}` : 'Ticket Details'}
          </DialogTitle>
          <DialogContent dividers sx={{ backgroundColor: '#f4f4f9' }}>
            {selectedTicket && (
              <DialogContentText component="div">
                <Typography variant="body1" sx={{ marginBottom: 1 }}>
                  <strong>Name:</strong> {selectedTicket.name}
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: 1 }}>
                  <strong>Email:</strong> {selectedTicket.email}
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: 1 }}>
                  <strong>Support Type:</strong> {selectedTicket.support_type}
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: 1 }}>
                  <strong>Subject:</strong> {selectedTicket.subject}
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: 1 }}>
                  <strong>Message:</strong> {selectedTicket.message}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: selectedTicket.status === 'OPEN' ? 'green' : 'red',
                    fontWeight: 'bold'
                  }}
                >
                  <strong>Status:</strong> {selectedTicket.status}
                </Typography>
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions sx={{ backgroundColor: '#f4f4f9', borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }}>
            <Button onClick={handleCloseTicketModal} sx={{ color: '#0E4961', fontWeight: 'bold' }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  
    // Desktop view: show the full table.
    const desktopView = (
      <Box
        sx={{
          padding: 4,
          borderRadius: 4,
          minWidth: 1200,
          minHeight: '100vh',
          margin: 'auto',
          backgroundColor: '#71BAE6'
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#fff', marginBottom: 2 }} gutterBottom>
          Tickets Raised
        </Typography>
        {tickets.length > 0 ? (
          <TableContainer component={Paper} sx={{ borderRadius: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                    Ticket No
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                    Name
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                    Email Address
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                    Support Type
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                    Subject
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                    Message
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tickets.map((ticket) => (
                  <TableRow key={ticket.id} sx={{ '&:hover': { backgroundColor: '#e0f7fa' } }}>
                    <TableCell align="center" sx={{ color: '#fff' }}>
                      {ticket.ticket_no}
                    </TableCell>
                    <TableCell align="center" sx={{ color: '#fff' }}>
                      {ticket.name}
                    </TableCell>
                    <TableCell align="center" sx={{ color: '#fff' }}>
                      {ticket.email}
                    </TableCell>
                    <TableCell align="center" sx={{ color: '#fff' }}>
                      {ticket.support_type}
                    </TableCell>
                    <TableCell align="center" sx={{ color: '#fff' }}>
                      {ticket.subject}
                    </TableCell>
                    <TableCell align="center" sx={{ color: '#fff' }}>
                      {ticket.message}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: ticket.status === 'OPEN' ? 'lightgreen' : '#ff8a80',
                        fontWeight: 'bold'
                      }}
                    >
                      {ticket.status}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box sx={{ textAlign: 'center', padding: 4 }}>
            <Typography variant="h6" sx={{ color: 'red' }}>
              No Tickets Raised
            </Typography>
            <Typography variant="body1" sx={{ color: '#888' }}>
              You haven't raised any tickets yet. If you need assistance, feel free to raise a ticket!
            </Typography>
          </Box>
        )}
      </Box>
    );
  
    return isSmallScreen ? mobileView : desktopView;
  }
  

function CustomerSupportScreen() {
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [token, setToken] = useState('');
    const [userId, setUserId] = useState(null);
    const [userDetails, setUserDetails] = useState({});
    const [orders, setOrders] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [formData, setFormData] = useState({
        user_id: '',
        name: '',
        email: '',
        support_type: '',
        order_id: '',
        subject: '',
        message: ''
    });
    const [isAddBtnLoading, setIsAddBtnLoading] = useState(false);
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const getUser = async () => {
        try {
            const response = await axios.get(`${API.BASE_URL}user/getUserDetails/${userId}`);
            const user = response?.data?.data?.user;
            const tok = response?.data?.data?.jwt;
            if (user) {
                setUserDetails(user);
                setToken(tok);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    user_id: user._id,
                    name: user.name || '',
                    email: user.email || ''
                }));
            }
        } catch (error) {
            console.error("Error fetching user details", error);
        }
    };

    const getorders = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.get(`${API.BASE_URL}user/orders`, { headers });
            const allOrders = response?.data?.data?.orders || [];
            const latestOrders = allOrders.slice(-5);
            setOrders(latestOrders);
        } catch (error) {
            console.log("get orders error :", error);
        }
    };

    const fetchTickets = async (userId) => {
        try {
            const headers = {
                Authorization: `Bearer ${token}`
            };
            const response = await axios.get(`${API.BASE_URL}customer-support/user-tickets?user_id=${userId}`, { headers });
            const ticketData = response?.data?.data?.tickets;
            setTickets(ticketData.length > 0 ? ticketData : []);
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                // Optionally handle error messaging
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsAddBtnLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.post(`${API.BASE_URL}customer-support/add-ticket`, formData, { headers });
            console.log("Successful", response);
            setIsAddBtnLoading(false);
            toast.success("Ticket raised successfully!!!");
            navigate('/');
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (error.response?.status === 406) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error;
                } else if (error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }
                toast.error(errorMessage);
            }
        } finally {
            setIsAddBtnLoading(false);
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const userIdFromUrl = params.get('userId');
        if (userIdFromUrl) {
            setUserId(userIdFromUrl);
            console.log('userIdFromUrl :', userIdFromUrl);
        }
    }, [location]);

    useEffect(() => {
        if (userId) {
            getUser();
        }
    }, [userId]);

    useEffect(() => {
        if (token) {
            getorders();
            const params = new URLSearchParams(location.search);
            const userIdFromUrl = params.get('userId');
            if (userIdFromUrl) {
                fetchTickets(userIdFromUrl);
            }
        }
    }, [token]);

    const handleWhatsappClick = () => {
        const phoneNumber = "918007665755";
        const message = encodeURIComponent("Hello! Xpanse Team");
        window.open(`https://wa.me/${phoneNumber}?text=${message}`, "_blank");
    };

    // Define each section as a separate variable

    const supportPolicy = (
        <Box sx={{ padding: 0, backgroundColor: '#71BAE6', borderRadius: 3, color: '#fff', maxWidth: 1200, margin: 'auto' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img onClick={handleWhatsappClick} src={Images.customerSupport} />
            </div>
        </Box>
    );

    const supportForm = (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                backgroundColor: '#71BAE6',
                padding: 4,
                borderRadius: 3,
                color: '#fff',
                maxWidth: { xs: 1200, md: 500 },
                margin: 'auto'
            }}
        >
            <Typography variant='h6' align="center" sx={{ marginBottom: 2, fontSize: '1.2rem' }}>
                We're here to help! Submit your queries, and we'll respond as soon as possible.
            </Typography>
            <TextField
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                required
                InputProps={{ style: { color: '#121111', backgroundColor: '#fff' } }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': { borderColor: '#fff' },
                        '&:hover fieldset': { borderColor: '#ccc' }
                    }
                }}
            />
            <TextField
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                required
                InputProps={{ style: { color: '#121111', backgroundColor: '#fff' } }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': { borderColor: '#fff' },
                        '&:hover fieldset': { borderColor: '#ccc' }
                    }
                }}
            />
            <TextField
                select
                label="Select Support Type"
                name="support_type"
                value={formData.support_type}
                onChange={handleChange}
                fullWidth
                required
                InputProps={{ style: { color: '#121111', backgroundColor: '#fff' } }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': { borderColor: '#fff' },
                        '&:hover fieldset': { borderColor: '#ccc' }
                    }
                }}
            >
                <MenuItem value="">Select Support Type</MenuItem>
                <MenuItem value="GENERAL-ENQUIRY">GENERAL-ENQUIRY</MenuItem>
                {userId && <MenuItem key="refund" value="PAYMENT-REFUND">PAYMENT-REFUND</MenuItem>}
                {userId && <MenuItem key="failed" value="ORDER-FAILURE">ORDER-FAILURE</MenuItem>}
            </TextField>
            <TextField
                select
                label="Select The Outlet"
                name="support_type"
                value={formData.support_type}
                onChange={handleChange}
                fullWidth
                required
                InputProps={{ style: { color: '#121111', backgroundColor: '#fff' } }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': { borderColor: '#fff' },
                        '&:hover fieldset': { borderColor: '#ccc' }
                    }
                }}
            >
                <MenuItem value="">Select the Outlet</MenuItem>
                <MenuItem value="Miramar">Xpanse Miramar</MenuItem>
                <MenuItem value="Belagavi">Xpanse Belagavi</MenuItem>
            </TextField>
            {formData?.support_type === 'PAYMENT-REFUND' && (
                <TextField
                    select
                    label="Select Order"
                    name="order_id"
                    value={formData.order_id}
                    onChange={handleChange}
                    fullWidth
                    required
                    InputProps={{ style: { color: '#121111', backgroundColor: '#fff' } }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': { borderColor: '#fff' },
                            '&:hover fieldset': { borderColor: '#ccc' }
                        }
                    }}
                >
                    <MenuItem value="">Select Order Number</MenuItem>
                    {orders?.map((order) => (
                        <MenuItem key={order?.order?._id} value={order?.order?._id}>
                            Order No. #{order?.order?.order_sequence}{order?.order?.order_no}
                        </MenuItem>
                    ))}
                </TextField>
            )}
            <TextField
                label="Subject"
                name="subject"
                type="text"
                value={formData.subject}
                onChange={handleChange}
                fullWidth
                required
                InputProps={{ style: { color: '#121111', backgroundColor: '#fff' } }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': { borderColor: '#fff' },
                        '&:hover fieldset': { borderColor: '#ccc' }
                    }
                }}
            />
            <TextField
                label="Message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                multiline
                rows={4}
                fullWidth
                required
                InputProps={{ style: { color: '#121111', backgroundColor: '#fff' } }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': { borderColor: '#fff' },
                        '&:hover fieldset': { borderColor: '#ccc' }
                    }
                }}
            />
            {isAddBtnLoading ? (
                <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    sx={{
                        borderRadius: '20px',
                        padding: 1.5,
                        backgroundColor: '#1E9CED',
                        color: '#fff',
                        fontWeight: 'bold',
                        '&:hover': { backgroundColor: '#ccc' }
                    }}
                >
                    Submitting
                </Button>
            ) : (
                <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    sx={{
                        borderRadius: '20px',
                        padding: 1.5,
                        backgroundColor: '#1E9CED',
                        color: '#fff',
                        fontWeight: 'bold',
                        '&:hover': { backgroundColor: '#ccc' }
                    }}
                >
                    Submit
                </Button>
            )}
            <Box sx={{ marginTop: "10px", textAlign: "center" }}>
                <Typography sx={{ color: "#555", marginTop: "10px", fontSize: "14px" }}>
                    If you need urgent assistance, please call our customer support hotline. <a href=''>[+91 - 77200 05755]</a>
                </Typography>
            </Box>
        </Box>
    );

    // Render using Tabs for small screens and the original layout for larger screens
    if (isSmallScreen) {
        return (
            <div className='supportMain'>
                <Tabs value={tabValue} sx={{ backgroundColor: '#fff' }} onChange={handleTabChange} variant="fullWidth">
                    <Tab label="Policy" id="tab-0" aria-controls="tabpanel-0" />
                    <Tab label="Support Form" id="tab-1" aria-controls="tabpanel-1" />
                    {tickets.length > 0 && (
                        <Tab label="Tickets" id="tab-2" aria-controls="tabpanel-2" />
                    )}
                </Tabs>
                {tabValue !== 2 && (
                <Stack sx={{ backgroundColor: '#0E4961', padding: '10px 0 10px 0' }} direction={'row'} alignItems={'center'} justifyContent={'space-around'}>
                    <img style={{ width: '20%' }} src={Images.xpanseNavLogo} />
                    <Typography variant='h5' sx={{ color: '#fff' }}>We're here to help!</Typography>
                </Stack>
                )}
                <TabPanel value={tabValue} index={0}>
                    {supportPolicy}
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    {supportForm}
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <TicketsSection userId={userId} tickets={tickets} isSmallScreen={isSmallScreen} />
                </TabPanel>
            </div>
        );
    } else {
        return (
            <div className='supportMain'>
                <Grid container justifyContent={'center'}>
                    <Grid item={6}>
                        {supportPolicy}
                    </Grid>
                    <Grid item={2}>
                        {supportForm}
                    </Grid>
                </Grid>
                <TicketsSection userId={userId} tickets={tickets} isSmallScreen={isSmallScreen} />
            </div>
        );
    }
}

export default CustomerSupportScreen;
