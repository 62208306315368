import React, { useEffect, useState } from 'react'
import { API, GOOGLE_MAP } from '../../../utils/Api';
import axios from 'axios';
import { Box, Button, Grid, Hidden, Typography, useMediaQuery } from '@mui/material';
import Images from '../../../utils/Images';
import { useNavigate } from 'react-router-dom';
import { KeyboardArrowLeft } from '@mui/icons-material';
import toast from 'react-hot-toast';
import OutletSkeleton from '../../../components/SkeletonComponents/OutletSkeleton';

const DeliveryOutletScreen = () => {
    const navigate = useNavigate();
    const [allOutlets, setAllOutlets] = useState([]);
    const [selectedStore, setSelectedStore] = useState([]);
    const [fetchingOutlets, setFetchingOutlets] = useState(false);
    const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
    const selectedDeliveryAddress = JSON.parse(localStorage.getItem("selectedAddress"));
    const latitude = localStorage.getItem("deliveryLatitude") || selectedDeliveryAddress?.coordinates?.latitude;
    const longitude = localStorage.getItem("deliveryLongitude") || selectedDeliveryAddress?.coordinates?.longitude;

    // console.log('selected address: ', selectedDeliveryAddress?.coordinates?.longitude);

    const [distance, setDistance] = useState(null);

    useEffect(() => {
        // Use dynamic origin if needed, or use the fixed origin from the URL
        // For example, using dynamic coordinates:
        const origin = `${latitude},${longitude}`;
        const destination = `15.5536192,73.8102094`;
        const apiKey = '8qkY7uxOWzHeRDGJk1OM0hTnrlkH7dDTxd4zBmiJ';

        // Construct the URL using encodeURIComponent to properly encode the coordinates
        const url = `https://api.olamaps.io/routing/v1/distanceMatrix?origins=${encodeURIComponent(origin)}&destinations=${encodeURIComponent(destination)}&mode=driving&api_key=${apiKey}`;

        // Alternatively, if you want to use the fixed origin from your provided URL, you can set:
        // const url = "https://api.olamaps.io/routing/v1/distanceMatrix?origins=15.487581838695137%2C%2073.8197504&destinations=15.5536192%2C%2073.8102094&mode=driving&api_key=8qkY7uxOWzHeRDGJk1OM0hTnrlkH7dDTxd4zBmiJ";

        fetch(url)
            .then(response => response.json())
            .then(data => {
                // Assuming the response structure is:
                // { results: { distances: [ [0, <distanceInMeters>] } } }
                console.log('data', data.rows[0].elements[0].distance);

                const distanceInMeters = data.rows[0].elements[0].distance;
                const distanceInKm = (distanceInMeters / 1000).toFixed(2);
                setDistance(distanceInKm);
            })
            .catch(error => {
                console.error('Error fetching distance:', error);
            });
    }, [latitude, longitude]);


    const getOutlets = async () => {
        setFetchingOutlets(true);
        try {
            const response = await axios.post(`${API.BASE_URL}outlet/getNearbyOutlets`, {
                "latitude": latitude,
                "longitude": longitude,
                "minimumRadiusInKm":"5"
            }, {
                // headers: {
                //   Authorization: `Bearer ${usertoken}`,
                // },
            })
            console.log('All delivery outlets fetched', response?.data, "Latitude", latitude, "Longitude", longitude);
            setAllOutlets(response?.data?.data?.outlets);
            setFetchingOutlets(false);
        } catch (error) {
            console.log("Error fetching all the outlets", error);
        } finally {
            setFetchingOutlets(false);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getOutlets();
        if (selectedDeliveryAddress) {
        }
    }, []);

    const handleStoreClick = (outlet) => {
        localStorage.setItem('selectedStoreData', JSON.stringify(outlet));
        console.log('Selected store saved to local storage', outlet);
        toast.success("Outlet selected");
        if (isMdUp) {
            window.location.reload();
        } else {
            navigate('/productsListing');
        }
    };

    return (
        <Box className="pickupOutletScreen">
            {fetchingOutlets ? (
                <>
                    <Typography className="title" onClick={() => navigate('/advertiseScreen')}><Hidden mdUp><KeyboardArrowLeft /></Hidden> Select An Outlet Nearby</Typography>
                    <Box sx={{ margin: '8px 0 0 0' }}>
                        <OutletSkeleton />
                    </Box>
                </>
            ) : (
                <>
                    {/* <div>
                        <h2>Distance Between Coordinates</h2>
                        {distance ? <p>Distance: {distance} km</p> : <p>Calculating...</p>}
                    </div> */}
                    {allOutlets?.length === 0 ? (
                        <Typography className="title" onClick={() => navigate('/advertiseScreen')}><Hidden mdUp><KeyboardArrowLeft /></Hidden>No outlets found near your area.</Typography>
                    ) : (
                        <Typography className="title" onClick={() => navigate('/advertiseScreen')}><Hidden mdUp><KeyboardArrowLeft /></Hidden> Select An Outlet Nearby</Typography>
                    )}
                    {allOutlets?.map(outlet => (
                        <Grid className="pickupOutlet" key={outlet?._id} container>
                            <Grid item xs={5}>
                                <img style={{ borderRadius: '12px', width: '100%', height: '90%', objectFit: 'cover', filter: outlet?.unavailabilityMessage ? 'grayscale(100%)' : 'none' }} src={outlet?.outletImage} alt={outlet?.name} />
                            </Grid>
                            <Grid item xs={7} sx={{ paddingLeft: '6px' }}>
                                <Typography className="outletName">{outlet?.name}</Typography>
                                <Typography className="outletCity">{outlet?.city}, {outlet?.state}, {outlet?.country}</Typography>
                                <Typography className="outletAddress">{outlet?.address}</Typography>
                                <Typography className="outletAddress">{outlet?.pincode}</Typography>
                                {/* <Box className="openMapButton">Open Maps</Box> */}
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Box className="openMapButton" sx={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => {
                                        const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${outlet?.coordinates?.latitude},${outlet?.coordinates?.longitude}`;
                                        window.open(googleMapsUrl, '_blank');
                                    }}>
                                        Open Maps
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                {outlet?.orderAcceptType === 'PICKUP' ? (
                                    <>
                                        <Box className="closedStoreText">
                                            <Typography variant='caption' sx={{ color: 'red' }}>This store is currently not accepting delivery orders.</Typography>
                                        </Box>
                                    </>
                                ) : (
                                    outlet?.unavailabilityMessage ? (
                                        <Box className="closedStoreText">
                                            <Typography variant='caption' sx={{ color: 'red' }}>{outlet?.unavailabilityMessage}</Typography>
                                        </Box>
                                    ) : (
                                        <Box onClick={() => handleStoreClick(outlet)} className="selectStoreBtn">
                                            <Typography variant='body1'>Select Store</Typography>
                                        </Box>
                                    )
                                )}
                            </Grid>
                        </Grid>
                    ))}
                </>
            )}

        </Box>
    )
}

export default DeliveryOutletScreen;

// import React, { useEffect, useState, useCallback } from 'react';
// import TextField from '@mui/material/TextField';
// import { Autocomplete } from '@react-google-maps/api';
// import { Api, SearchSharp } from '@mui/icons-material';
// import { GOOGLE_MAP } from '../utils/Api';
// import { Box, InputAdornment } from '@mui/material';

// function PlacesAutocomplete({ onCoordinatesChange }) {
//   const [scriptLoaded, setScriptLoaded] = useState(false);
//   const [scriptError, setScriptError] = useState(false);
//   const [autocomplete, setAutocomplete] = useState(null);
//   const [address, setAddress] = useState("");
//   const apiKey = GOOGLE_MAP.GOOGLE_MAP_KEY

//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
//     script.async = true;
//     script.defer = true;
//     script.onload = () => setScriptLoaded(true);
//     script.onerror = () => {
//       setScriptError(true);
//       script.remove();
//     };
//     document.body.appendChild(script);
//     return () => {
//       if (document.body.contains(script)) {
//         document.body.removeChild(script);
//       }
//     };
//   }, [apiKey]);

//   const handleOnLoad = useCallback((autoC) => {
//     setAutocomplete(autoC);
//   }, []);

//   const handlePlaceSelect = () => {
//     if (autocomplete !== null) {
//       const place = autocomplete.getPlace();
//       setAddress(place.formatted_address);
//       if (place.geometry) {
//         const lat = place.geometry.location.lat();
//         const lng = place.geometry.location.lng();
//         onCoordinatesChange({ lat, lng });
//         console.log(`Coordinates: Latitude: ${lat}, Longitude: ${lng}`);
//       } else {
//         console.log('No geometry data available for this place.');
//       }
//     } else {
//       console.log('Autocomplete is not loaded yet!');
//     }
//   };

//   if (scriptError) return <div>Error loading Google Maps</div>;
//   if (!scriptLoaded) return <div>Loading...</div>;

//   return (
//     <Autocomplete onLoad={handleOnLoad} onPlaceChanged={handlePlaceSelect} options={{ componentRestrictions: { country: 'in' } }}>
//       <TextField
//         fullWidth
//         // label="Enter location"
//         value={address}
//         onChange={(e) => setAddress(e.target.value)}
//         sx={{ backgroundColor: '#fff' }}
//         InputLabelProps={{ style: { color: '#2196F3' } }}
//         InputProps={{
//           startAdornment: (
//             <InputAdornment position="start">
//               <Box sx={{ display: 'flex', color: '#1DA1F2' }}>
//                 <SearchSharp color="#1DA1F2" />
//               </Box>
//             </InputAdornment>
//           ),
//           style: { borderColor: '#2196F3' },
//         }}
//       />
//     </Autocomplete>
//   );
// }

// export default PlacesAutocomplete;
