import { Box, CircularProgress, Drawer, Grid, Hidden, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Images from '../utils/Images';
import axios from 'axios';
import { API } from '../utils/Api';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setData } from '../store/Cart/cartSlice';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import AddonIconComponent from './AddonIconComponent';
import { CloseOutlined } from '@mui/icons-material';
import ProductDrawer from './ProductDrawer';

const FavouriteProducts = () => {
  const token = localStorage.getItem('token');
  const [products, setProducts] = useState([]);
  const outlet = localStorage.getItem("selectedStoreData");
  const deliveryType = localStorage.getItem("selectedOrderType");
  const outletObject = JSON.parse(outlet);
  const outletId = outletObject?._id;
  const dispatch = useDispatch();
  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('selectedStoreData')));
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [selectedAddons, setSelectedAddons] = useState({});
  const [isFavorited, setIsFavorited] = useState(false);
  const [wishlistData, setWishlistData] = useState([]);
  const [addingToCart, setAddingToCart] = useState(false);

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setQuantity(1);
    setSelectedAddons({});
  };

  const handleAddonSelect = (addonId, addonValue) => {
    setSelectedAddons((prevSelected) => ({
      ...prevSelected,
      [addonId]: addonValue,
    }));
    console.log('Addons', { addonId, ...addonValue });
  };

  const isProductInWishlist = (productId) => {
    return wishlistData.includes(productId);
  };

  const [totalPrice, setTotalPrice] = useState(selectedProduct?.offerPrice || 0);
  const [totalMrpPrice, setTotalMrpPrice] = useState(selectedProduct?.mrp || 0);
  const [offerPercentage, setOfferPercentage] = useState(0);

  useEffect(() => {
    const calculateTotalPrice = () => {
      let price = selectedProduct?.offerPrice || 0;
      let mrp = selectedProduct?.mrp || 0;
      Object.values(selectedAddons).forEach(addon => {
        price += addon.offerPrice;
        mrp += addon.mrp;
      });
      setTotalPrice(price);
      setTotalMrpPrice(mrp);
    };
    calculateTotalPrice();
  }, [selectedAddons, selectedProduct]);

  useEffect(() => {
    if (totalMrpPrice > 0 && totalPrice < totalMrpPrice) {
      const discount = ((totalMrpPrice - totalPrice) / totalMrpPrice) * 100;
      setOfferPercentage(discount);
    } else {
      setOfferPercentage(0);
    }
  }, [totalPrice, totalMrpPrice]);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const getFavouriteProducts = async () => {
    if (!token || !outletData?._id) {
      return;
    }
    try {
      const response = await axios.get(`${API.BASE_URL}order/products/favourite/${outletData?._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProducts(response?.data?.data?.favouriteProducts);
      // console.log('Favourite Products API Response', response.data.data );
    } catch (error) {
      console.log('Favourite Products Error API Response', error.response);
    }
  };

  useEffect(() => {
    getFavouriteProducts();
    setOutletData(JSON.parse(localStorage.getItem('selectedStoreData')));
  }, []);

  const fetchUserCartData = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}cart/userCart/${outletData._id}/${deliveryType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setData(response?.data));
      console.log('Cart Screen Data', response?.data);
    } catch (error) {
      dispatch(setData([]));
      console.log('Cart Screen Error', error?.response?.data);
    }
  };

  const handleAddToCart = async () => {
    setAddingToCart(true);
    try {
      const addonsArray = Object.keys(selectedAddons).map((addonId) => ({
        _id: addonId,
        selectedValue: selectedAddons[addonId].value,
        addonValueId: selectedAddons[addonId]._id,
      }));
      const defaultAddonsArray = selectedProduct.addons && Array.isArray(selectedProduct.addons)
        ? selectedProduct.addons
          .filter(addon => addon?.addonValues?.some(value => value?.isDefault && !selectedAddons[addon?._id]))
          .map(addon => {
            const defaultAddonValue = addon?.addonValues?.find(value => value?.isDefault);
            return {
              _id: addon._id,
              selectedValue: defaultAddonValue.value,
              addonValueId: defaultAddonValue._id,
            };
          })
        : [];
      const allAddonsArray = [...addonsArray, ...defaultAddonsArray];
      const cartItem = {
        ordersType: deliveryType,
        outlet: outletId,
        cartItems: [
          {
            product: selectedProduct._id,
            quantity: quantity,
            addons: allAddonsArray.length > 0 ? allAddonsArray : undefined,
          }
        ],
      };
      const response = await axios.post(`${API.BASE_URL}cart`, cartItem, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      handleCloseModal();
      fetchUserCartData();
      setAddingToCart(false);
      toast.success('Product added to cart!!!');
      // console.log(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setAddingToCart(false);
    }
  };

  const handleIncreaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleFavoriteClick = async () => {
    try {
      if (isProductInWishlist(selectedProduct._id)) {
        const response = await axios.post(
          `${API.BASE_URL}wishlist/remove`,
          { productId: selectedProduct._id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setIsFavorited(false);
        toast.success('Product removed from favorites!!!');
        // console.log("Wishlist Remove Success", response.data);
      } else {
        const response = await axios.post(
          `${API.BASE_URL}wishlist/add`,
          { productId: selectedProduct._id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setIsFavorited(true);
        toast.success('Product added to favorites!!!');
        console.log("Wishlist Add Success", response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRandomRating = () => {
    return (Math.random() * (4.2 - 3.7) + 3.7).toFixed(1);
  };

  return (
    <Box className="favouriteProducts">
      {products.length > 0 && (<Typography className="title">Your Favourites</Typography>)}
      <Box className="favouriteProductsContainer">
        {products.length > 0 ? (
          products.map((product, index) => (
            <Box key={product?.product?._id} className="productBox" onClick={() => handleProductClick(product?.product)}>
              <Box className="imageBox">
                <img className="image" src={product?.product?.displayImageUrl} />
              </Box>
              <Box className="productDetail" onClick={() => handleProductClick(product?.product)}>
                <>
                  {product?.product?.foodType === 'VEG/VEGAN' ? (
                    <Stack direction={'row'} sx={{ marginRight: '35px' }}>
                      <img style={{ height: '20px', marginRight: '4px', marginLeft: '6px' }} className='veg' src={Images.veg} alt='veg' />
                      <img style={{ height: '20px', marginRight: '4px' }} className='veg' src={Images.vegan} alt='vegan' />
                    </Stack>
                  ) : (
                    <img style={{ height: '20px' }} className='veg' src={product?.product?.foodType === 'VEG' ? Images.veg : (product?.product?.foodType === 'VEGAN' ? Images.vegan : Images.nonVeg)} alt={product?.product?.foodType === 'VEG' ? 'veg' : (product?.product?.foodType === 'VEGAN' ? 'vegan' : 'non-veg')} />
                  )}
                </>
                <Typography className="productName">{product?.product?.name}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Box className="priceBox" sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography className="offerPrice">₹{product?.product?.offerPrice}</Typography>
                    <Typography className="mrp">₹{product?.product?.mrp}</Typography>
                    <Hidden smDown>
                      <Box className="percentOff">
                        {product?.product?.offerPercentage?.toFixed(0)}% OFF
                      </Box>
                    </Hidden>
                  </Box>
                  <Hidden smUp>
                    <Box className="percentOff">
                      {product?.product?.offerPercentage?.toFixed(0)}% OFF
                    </Box>
                  </Hidden>
                </Box>
              </Box>
              <Box className={product?.product?.isExhausted ? "exhaustedButton" : "button"} onClick={() => handleProductClick(product?.product)}>
                {product?.product?.isExhausted ? "Out of stock" : "ADD"}
              </Box>
            </Box>
          ))
        ) : (
          <Hidden mdDown>
            <Box sx={{ height: '100px' }}>
            </Box>
          </Hidden>
        )}
      </Box>
      <ProductDrawer
        open={!!selectedProduct}
        onClose={handleCloseModal}
        selectedProduct={selectedProduct}
        handleAddToCart={handleAddToCart}
        isFavorited={isFavorited}
        handleFavoriteClick={handleFavoriteClick}
        quantity={quantity}
        handleIncreaseQuantity={handleIncreaseQuantity}
        handleDecreaseQuantity={handleDecreaseQuantity}
        selectedAddons={selectedAddons}
        handleAddonSelect={handleAddonSelect}
        totalPrice={totalPrice}
        totalMrpPrice={totalMrpPrice}
        offerPercentage={offerPercentage}
        addingToCart={addingToCart}
      />
    </Box>
  )
}

export default FavouriteProducts;