import React, { useEffect, useState } from 'react';
import './App.css';
import './index.css';
import './sass/main.scss';
import AppRoutes from './AppRoutes';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Toaster } from 'react-hot-toast';
// import { SearchProvider } from './components/SearchBar';
import { ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

function App() {

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
      <Toaster position="bottom-center" toastOptions={{
          success: {
            duration: 3000,
            style: {
              borderRadius: '30px',
              backgroundColor: '#0E4961',
              color: '#fff'
            },
          },
          error: {
            duration: 3000,
            style: {
              backgroundColor: '#E34234',              
              borderRadius: '30px',
              color: '#fff'
            },
          },
        }} 
        reverseOrder={true} 
      />
      </ThemeProvider>
      {/* <ToastContainer position="bottom-center" autoClose={3000} theme="colored" hideProgressBar={true} pauseOnHover={false} /> */}
    </div>
  );
}

export default App;

// import React, { useEffect, useState } from 'react';
// import { Box, Button, Grid, List, ListItem, ListItemText, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
// import { useLocation, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import Images from '../../../utils/Images';
// import { API } from '../../../utils/Api';
// import toast from 'react-hot-toast';
// import { WhatsApp } from '@mui/icons-material';

// function CustomerSupportScreen() {
//     const location = useLocation();
//     const [token, setToken] = useState('');
//     const [userId, setUserId] = useState(null);
//     const [userDetails, setUserDetails] = useState({});
//     const [orders, setOrders] = useState([]);
//     const [tickets, setTickets] = useState([])
//     const [formData, setFormData] = useState({
//         user_id: '',
//         name: '',
//         email: '',
//         support_type: '',
//         order_id: '',
//         subject: '',
//         message: ''
//     });
//     const [isAddBtnLoading, setIsAddBtnLoading] = useState(false);
//     const navigate = useNavigate();

//     const getUser = async () => {
//         try {
//             const response = await axios.get(`${API.BASE_URL}user/getUserDetails/${userId}`);
//             const user = response?.data?.data?.user;
//             const tok = response?.data?.data?.jwt;
//             if (user) {
//                 setUserDetails(user);
//                 setToken(tok);
//                 setFormData((prevFormData) => ({
//                     ...prevFormData,
//                     user_id: user._id,
//                     name: user.name || '',
//                     email: user.email || ''
//                 }));
//             }
//         } catch (error) {
//             console.error("Error fetching user details", error);
//         }
//     }

//     const getorders = async () => {
//         try {
//             const headers = {
//                 Authorization: `Bearer ${token}`,
//                 'Content-Type': 'application/json'
//             }
//             const response = await axios.get(`${API.BASE_URL}user/orders`, { headers });
//             const allOrders = response?.data?.data?.orders || [];
//             const latestOrders = allOrders.slice(-5);
//             setOrders(latestOrders);
//         } catch (error) {
//             console.log("get orders error :", error);
//         }
//     }

//     const fetchTickets = async (userId) => {
//         try {
//             const headers = {
//                 Authorization: `Bearer ${token}`
//             }
//             const response = await axios.get(`${API.BASE_URL}customer-support/user-tickets?user_id=${userId}`, { headers });
//             const ticketData = response?.data?.data?.tickets;
//             setTickets(ticketData.length > 0 ? ticketData : []);
//         } catch (error) {
//             if (error.response && error.response?.data?.status === false) {
//                 let errorMessage = error.response.data.error;
//                 // toast.error(errorMessage, {
//                 //   position: "top-right",
//                 //   autoClose: 3000,
//                 //   theme: "colored",
//                 // });
//             }
//         }
//     };

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         // console.log("name :", name ,"value :", value);
//         setFormData((prevData) => ({
//             ...prevData,
//             [name]: value
//         }));
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setIsAddBtnLoading(true);
//         try {
//             const headers = {
//                 Authorization: `Bearer ${token}`,
//                 'Content-Type': 'application/json'
//             }
//             const response = await axios.post(`${API.BASE_URL}customer-support/add-ticket`, formData, { headers });
//             console.log("Successfull", response);
//             setIsAddBtnLoading(false);
//             toast.success("Ticket raised successfully!!!");
//             navigate('/')
//         } catch (error) {
//             if (error?.response) {
//                 let errorMessage = '';
//                 if (
//                     error.response?.status === 406
//                 ) {
//                     errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
//                 }
//                 else if (
//                     error.response?.status === 412
//                 ) {
//                     errorMessage = error?.response?.data?.data?.message;
//                 } else if (error.response?.status === 500) {
//                     errorMessage = error?.response?.data?.error
//                 }
//                 toast.error(errorMessage);
//             }
//         } finally {
//             setIsAddBtnLoading(false);
//         }
//     };

//     useEffect(() => {
//         const params = new URLSearchParams(location.search);
//         const userIdFromUrl = params.get('userId');
//         if (userIdFromUrl) {
//             setUserId(userIdFromUrl);
//             console.log('userIdFromUrl :', userIdFromUrl);
//         }
//     }, [location]);

//     useEffect(() => {
//         if (userId) {
//             getUser();
//         }
//     }, [userId]);

//     useEffect(() => {
//         if (token) {
//             getorders();
//             const params = new URLSearchParams(location.search);
//             const userIdFromUrl = params.get('userId');
//             if (userIdFromUrl) {
//                 fetchTickets(userIdFromUrl);
//             }
//         }
//     }, [token])

//     const handleWhatsappClick = () => {
//         const phoneNumber = "918007665755";
//         const message = encodeURIComponent(
//             "Hello! Xpanse Team"
//         );
//         window.open(`https://wa.me/${phoneNumber}?text=${message}`, "_blank");
//     };

//     return (
//         <div className='supportMain'>

//             <Box sx={{ padding: 4, backgroundColor: '#71BAE6', borderRadius: 3, color: '#fff', maxWidth: 1200, margin: 'auto' }}>
//                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                     <div style={{ marginRight: '15px' }}><img src={Images.xpanseNavLogo} style={{ height: '120px', width: '120px' }} alt="" /></div>
//                     <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold' }} className='xpanse-text'>
//                         XPANSE CUSTOMER SUPPORT
//                     </Typography>
//                 </div>
//                 <Grid container spacing={4} alignItems='center'>
//                     <Grid item xs={12} md={6}>
//                         <Paper elevation={3} sx={{ padding: 4, backgroundColor: '#DFEEF4' }}>
//                             <Typography variant="h5" gutterBottom sx={{ color: '#121212', fontWeight: 'bold' }}>
//                                 Customer Support Policy
//                             </Typography>
//                             <Typography sx={{ color: '#555' }}>
//                                 We are committed to providing you with the best customer service. If you have any issues regarding your orders, payments, or general queries,
//                                 feel free to reach out to us using the support form. Our team will address your concerns and respond as soon as possible.
//                             </Typography>
//                             <Typography sx={{ marginTop: 2, color: '#555' }}>
//                                 Policy Highlights:
//                             </Typography>
//                             <ul style={{ color: '#555' }}>
//                                 <li>Response time: Within 24 hours on business days.</li>
//                                 <li>Support hours: Monday to Friday, 9 AM to 6 PM.</li>
//                                 <li>General queries or concerns can be addressed using this form.</li>
//                                 <li>Refund requests will be processed within 5-7 business days.</li>
//                             </ul>
//                             <Typography sx={{ marginTop: 2, color: '#555' }}>
//                                 If you need urgent assistance, please call our customer support hotline.
//                                 <span style={{ color: 'blue', textDecoration: 'underline' }}>[+91 - 77200 05755]</span>
//                             </Typography>
//                         </Paper>
//                     </Grid>

//                     <Grid item xs={12} md={6}>
//                         <Box
//                             component="form"
//                             onSubmit={handleSubmit}
//                             sx={{
//                                 display: 'flex',
//                                 flexDirection: 'column',
//                                 gap: 2,
//                                 backgroundColor: '#71BAE6',
//                                 padding: 4,
//                                 borderRadius: 3,
//                                 color: '#fff',
//                             }}
//                         >

//                             <Typography variant='h6' align="center" sx={{ marginBottom: 2, fontSize: '1.2rem' }}>
//                                 We're here to help! Submit your queries, and we'll respond as soon as possible.
//                             </Typography>

//                             <TextField
//                                 label="Name"
//                                 name="name"
//                                 value={formData.name}
//                                 onChange={handleChange}
//                                 fullWidth
//                                 required
//                                 InputProps={{ style: { color: '#121111', backgroundColor: '#fff' } }}
//                                 sx={{
//                                     '& .MuiOutlinedInput-root': {
//                                         '& fieldset': { borderColor: '#fff' },
//                                         '&:hover fieldset': { borderColor: '#ccc' }
//                                     }
//                                 }}
//                             />

//                             <TextField
//                                 label="Email"
//                                 name="email"
//                                 type="email"
//                                 value={formData.email}
//                                 onChange={handleChange}
//                                 fullWidth
//                                 required
//                                 InputProps={{ style: { color: '#121111', backgroundColor: '#fff' } }}
//                                 sx={{
//                                     '& .MuiOutlinedInput-root': {
//                                         '& fieldset': { borderColor: '#fff' },
//                                         '&:hover fieldset': { borderColor: '#ccc' }
//                                     }
//                                 }}
//                             />

//                             <TextField
//                                 select
//                                 label="Select Support Type"
//                                 name="support_type"
//                                 value={formData.support_type}
//                                 onChange={handleChange}
//                                 fullWidth
//                                 required
//                                 InputProps={{ style: { color: '#121111', backgroundColor: '#fff' } }}
//                                 sx={{
//                                     '& .MuiOutlinedInput-root': {
//                                         '& fieldset': { borderColor: '#fff' },
//                                         '&:hover fieldset': { borderColor: '#ccc' }
//                                     }
//                                 }}
//                             >
//                                 <MenuItem value="">Select Support Type</MenuItem>
//                                 <MenuItem value="GENERAL-ENQUIRY">GENERAL-ENQUIRY</MenuItem>
//                                 {userId && (
//                                     <MenuItem key="refund" value="PAYMENT-REFUND">PAYMENT-REFUND</MenuItem>
//                                 )}
//                                 {userId && (
//                                     <MenuItem key="failed" value="ORDER-FAILURE">ORDER-FAILURE</MenuItem>
//                                 )}
//                             </TextField>

//                             <TextField
//                                 select
//                                 label="Select The Outlet"
//                                 name="support_type"
//                                 value={formData.support_type}
//                                 onChange={handleChange}
//                                 fullWidth
//                                 required
//                                 InputProps={{ style: { color: '#121111', backgroundColor: '#fff' } }}
//                                 sx={{
//                                     '& .MuiOutlinedInput-root': {
//                                         '& fieldset': { borderColor: '#fff' },
//                                         '&:hover fieldset': { borderColor: '#ccc' }
//                                     }
//                                 }}
//                             >
//                                 <MenuItem value="">Select the Outlet</MenuItem>
//                                 <MenuItem value="Miramar">Xpanse Miramar</MenuItem>
//                                 <MenuItem value="Belagavi">Xpanse Belagavi</MenuItem>
//                             </TextField>

//                             {formData?.support_type === 'PAYMENT-REFUND' && (
//                                 <TextField
//                                     select
//                                     label="Select Order"
//                                     name="order_id"
//                                     value={formData.order_id}
//                                     onChange={handleChange}
//                                     fullWidth
//                                     required
//                                     InputProps={{ style: { color: '#121111', backgroundColor: '#fff' } }}
//                                     sx={{
//                                         '& .MuiOutlinedInput-root': {
//                                             '& fieldset': { borderColor: '#fff' },
//                                             '&:hover fieldset': { borderColor: '#ccc' }
//                                         }
//                                     }}
//                                 >
//                                     <MenuItem value="">Select Order Number</MenuItem>
//                                     {orders?.map((order) => {
//                                         console.log("order :", order.orderItems[0],);

//                                         return (
//                                             <MenuItem key={order?.order?._id} value={order?.order?._id}>
//                                                 Order No. #{order?.order?.order_sequence}{order?.order?.order_no}
//                                             </MenuItem>
//                                         );
//                                     })}
//                                 </TextField>
//                             )}

//                             <TextField
//                                 label="Subject"
//                                 name="subject"
//                                 type="text"
//                                 value={formData.subject}
//                                 onChange={handleChange}
//                                 fullWidth
//                                 required
//                                 InputProps={{ style: { color: '#121111', backgroundColor: '#fff' } }}
//                                 sx={{
//                                     '& .MuiOutlinedInput-root': {
//                                         '& fieldset': { borderColor: '#fff' },
//                                         '&:hover fieldset': { borderColor: '#ccc' }
//                                     }
//                                 }}
//                             />

//                             <TextField
//                                 label="Message"
//                                 name="message"
//                                 value={formData.message}
//                                 onChange={handleChange}
//                                 multiline
//                                 rows={4}
//                                 fullWidth
//                                 required
//                                 InputProps={{ style: { color: '#121111', backgroundColor: '#fff' } }}
//                                 sx={{
//                                     '& .MuiOutlinedInput-root': {
//                                         '& fieldset': { borderColor: '#fff' },
//                                         '&:hover fieldset': { borderColor: '#ccc' }
//                                     }
//                                 }}
//                             />

//                             {isAddBtnLoading ? (
//                                 <Button
//                                     variant="contained"
//                                     type="submit"
//                                     fullWidth
//                                     sx={{ borderRadius: '20px', padding: 1.5, backgroundColor: '#1E9CED', color: '#fff', fontWeight: 'bold', '&:hover': { backgroundColor: '#ccc' } }}
//                                 >
//                                     Submitting
//                                 </Button>
//                             ) : (
//                                 <Button
//                                     variant="contained"
//                                     type="submit"
//                                     fullWidth
//                                     sx={{ borderRadius: '20px', padding: 1.5, backgroundColor: '#1E9CED', color: '#fff', fontWeight: 'bold', '&:hover': { backgroundColor: '#ccc' } }}
//                                 >
//                                     Submit
//                                 </Button>
//                             )}
//                             <Box sx={{ marginTop: "10px", textAlign: "center" }}>
//                                 <Button
//                                     onClick={handleWhatsappClick}
//                                     startIcon={<WhatsApp style={{ color: "white" }} />}
//                                     sx={{
//                                         backgroundColor: "#25D366",
//                                         color: "white",
//                                         padding: "10px 20px",
//                                         borderRadius: "8px",
//                                         fontSize: "16px",
//                                         fontWeight: "bold",
//                                         textTransform: "none",
//                                         "&:hover": {
//                                             backgroundColor: "#1EBE57",
//                                         },
//                                     }}
//                                 >
//                                     Chat with Us on WhatsApp
//                                 </Button>
//                                 <Typography
//                                     sx={{
//                                         color: "#555",
//                                         marginTop: "10px",
//                                         fontSize: "14px",
//                                     }}
//                                 >
//                                     Chat Support available from <strong>10 AM</strong> to <strong>6 PM</strong><br />
//                                     <strong>Monday</strong> to <strong>Friday</strong>
//                                 </Typography>
//                             </Box>
//                         </Box>
//                     </Grid>
//                 </Grid>
//             </Box>

//             {userId && (
//                 <Box sx={{ padding: 4, borderRadius: 4 }}>
//                     <Typography variant="h5" sx={{ fontWeight: 'bold' }} gutterBottom color="white">
//                         Tickets Raised
//                     </Typography>

//                     {tickets.length > 0 ? (
//                         <TableContainer component={Paper}>
//                             <Table>
//                                 <TableHead>
//                                     <TableRow>
//                                         <TableCell align="center">Ticket No</TableCell>
//                                         <TableCell align="center">Name</TableCell>
//                                         <TableCell align="center">Email Address</TableCell>
//                                         <TableCell align="center">Support Type</TableCell>
//                                         <TableCell align="center">Subject</TableCell>
//                                         <TableCell align="center">Message</TableCell>
//                                         <TableCell align="center">Status</TableCell>
//                                     </TableRow>
//                                 </TableHead>
//                                 <TableBody>
//                                     {tickets.map((ticket) => (
//                                         <TableRow key={ticket.id}>
//                                             <TableCell align="center">{ticket.ticket_no}</TableCell>
//                                             <TableCell align="center">{ticket.name}</TableCell>
//                                             <TableCell align="center">{ticket.email}</TableCell>
//                                             <TableCell align="center">{ticket.support_type}</TableCell>
//                                             <TableCell align="center">{ticket.subject}</TableCell>
//                                             <TableCell align="center">{ticket.message}</TableCell>
//                                             <TableCell align="center" style={{ color: ticket.status === 'OPEN' ? 'green' : 'red', fontWeight: 'bold' }}>{ticket.status}</TableCell>
//                                         </TableRow>
//                                     ))}
//                                 </TableBody>
//                             </Table>
//                         </TableContainer>
//                     ) : (
//                         <Box sx={{ textAlign: 'center', padding: 4 }}>
//                             <Typography variant="h6" sx={{ color: 'red' }}>
//                                 No Tickets Raised
//                             </Typography>
//                             <Typography variant="body1" sx={{ color: '#888' }}>
//                                 You haven't raised any tickets yet. If you need assistance, feel free to raise a ticket!
//                             </Typography>
//                         </Box>
//                     )}
//                 </Box>
//             )}

//         </div>
//     )
// }

// export default CustomerSupportScreen
