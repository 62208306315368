import React, { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { API } from '../utils/Api';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const outlet = localStorage.getItem("selectedStoreData");
  const outletObject = JSON.parse(outlet);
  const outletId = outletObject?._id;
  
  useEffect(() => {
    if (!outletId) {
      console.error('Outlet data is missing');
      return;
    }

    const SOCKET_OPTIONS = {
      pingInterval: 15000,
      pingTimeout: 30000,
    };

    // Initialize Socket Connection.
    const newSocket = io(API.SOCKET_URL, SOCKET_OPTIONS);

    newSocket.on('ping', () => {
      console.log('Ping sent to server');
    });

    newSocket.on('pong', () => {
      console.log('Pong received from server');
    });

    newSocket.on('connect_error', (err) => {
      console.error('Connection error:', err);
    });

    setSocket(newSocket);

    // Clean up socket connection when component unmounts.
    return () => {
      newSocket.disconnect();
    };
  }, [outletId]);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};
