//Production
// export const API = {
//     BASE_URL: 'https://api.xpansecafe.com/',
// }

// export const RAZOR_PAY = {
//     RAZOR_PAY_KEY: 'rzp_live_F5u8OhE3jITTVm',
// }


//Development
export const API = {
    BASE_URL: 'https://xpanse.dev.bexcart.com/',
    SOCKET_URL: 'https://xpanse.dev.bexcart.com/',
}

export const RAZOR_PAY = {
    RAZOR_PAY_KEY: 'rzp_test_ZxQ2jKSuJLuNC5',
}


export const GOOGLE_MAP = {
    GOOGLE_MAP_KEY: 'AIzaSyAkMTmrfVp5O6MFzLdcLACGt1FKCy2PVkY',
    OLA_MAP_KEY: '8qkY7uxOWzHeRDGJk1OM0hTnrlkH7dDTxd4zBmiJ',
}
