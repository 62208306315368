import { Box, Typography, Hidden, Grid, Stack } from '@mui/material';
import React from 'react';
import Images from '../utils/Images';
import { KeyboardArrowRight } from '@mui/icons-material';

const ProductCard = ({ product, quantityInCart, handleProductClick, matchesMdUp, isLastInRow, isLastRow, coupon }) => {

  const outletData = JSON.parse(localStorage.getItem("selectedStoreData"));
  const token = localStorage.getItem("token");

    // console.log('coupon data', coupon);

  return (
    <Box key={product?._id}
      sx={{
        ...(matchesMdUp ? { borderRight: isLastInRow ? 'none' : '1px dashed #D6D6D6' } : { borderRight: 'none', borderLeft: 'none', }),
        borderBottom: isLastRow ? 'none' : '1px dashed #D6D6D6',
        // paddingBottom: '35px',
        ...(matchesMdUp ? {} : {
          // borderBottom: productIndex === category.products.length - 1 ? 'none' : '1px dashed #6a6a6a',
          borderBottom: isLastRow ? 'none' : '1px dashed #D6D6D6',
          // borderImage: 'repeating-linear-gradient(90deg, #D6D6D6, #D6D6D6 10px, transparent 10px, transparent 14px) 2',
        }),
      }}
      className={`product-item ${product?.isExhausted ? 'exhausted' : ''}`}
    >
      <Hidden smUp>
        <Grid container onClick={() => handleProductClick(product)}>
          <Grid item xs={4} sm={4} className="imageBox" onClick={() => handleProductClick(product)}>
            <img className="image" src={product?.displayImageUrl} alt="Product" />
          </Grid>
          <Grid item xs={8} sm={8}>
            <Typography className="productName">{product?.name}</Typography>
            <Typography className="productPrice">
              <span className="productOfferprice">₹{product?.offerPrice}</span>
              {product?.mrp > product?.offerPrice && (
                <span className="productMrp">₹{product?.mrp}</span>
              )}
              {/* {product?.offerPercentage > 0 && (
                <Typography className="productOff">
                  {product.offerPercentage.toFixed(0)}% OFF
                </Typography>
              )} */}
              <>
                {product?.foodType === 'VEG/VEGAN' ? (
                  <Stack direction={'row'}>
                    <img className='veg-nonveg' src={Images.veg} alt='veg' />
                    <img style={{ height: '17px', marginRight: '4px', marginLeft: '0px' }} className='veg-nonveg' src={Images.vegan} alt='vegan' />
                  </Stack>
                ) : (
                  <img className='veg-nonveg' src={product?.foodType === 'VEG' ? Images.veg : (product?.foodType === 'VEGAN' ? Images.vegan : Images.nonVeg)} alt={product?.foodType === 'VEG' ? 'veg' : (product?.foodType === 'VEGAN' ? 'vegan' : 'non-veg')} />
                )}
              </>
            </Typography>
            {/* <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-around'}>
              <>
                {product?.foodType === 'VEG/VEGAN' ? (
                  <Stack direction={'row'}>
                    <img className='veg-nonveg' src={Images.veg} alt='veg' />
                    <img style={{ height: '17px', marginRight: '4px', marginLeft: '0px' }} className='veg-nonveg' src={Images.vegan} alt='vegan' />
                  </Stack>
                ) : (
                  <img className='veg-nonveg' src={product?.foodType === 'VEG' ? Images.veg : (product?.foodType === 'VEGAN' ? Images.vegan : Images.nonVeg)} alt={product?.foodType === 'VEG' ? 'veg' : (product?.foodType === 'VEGAN' ? 'vegan' : 'non-veg')} />
                )}
              </>
              <>
                {quantityInCart > 0 ? (
                  <Box className="addedBtn" onClick={() => handleProductClick(product)}>
                    Added
                    <Box className="addedNumber">
                      {quantityInCart}
                    </Box>
                  </Box>
                ) : (
                  <Box className={product?.isExhausted ? "exhaustedBtn" : "notAddedBtn"} onClick={!product?.isExhausted ? () => handleProductClick(product) : null}>
                    {product?.isExhausted ?
                      <>
                        <Typography className="exhaustedText">Out Of Stock</Typography>
                      </> :
                      <>
                        <Box sx={{ display: 'flex' }}>
                          <Typography>Add</Typography>
                          <KeyboardArrowRight className="arrowBtn" />
                        </Box>
                      </>
                    }
                  </Box>
                )}
              </>
            </Stack> */}
            <Grid item xs={12} className="bestCoupon">
              {coupon ? (
                <>
                  <Box className="bestCouponBox">
                    {coupon?.discountPercent ? (
                      (() => {
                        const discountAmount = (coupon?.discountPercent / 100) * product?.offerPrice;
                        const discountedPrice = product?.offerPrice - discountAmount;
                        if (!discountedPrice || isNaN(discountedPrice)) return null;
                        const fraction = discountedPrice - Math.floor(discountedPrice);
                        const displayPrice =
                          fraction >= 0.1 ? discountedPrice.toFixed(2) : discountedPrice.toFixed(0);
                        const discountPercentOnMrp =
                          ((product?.mrp - discountedPrice) / product?.mrp) * 100;

                        return (
                          <Typography className="bestCouponTag">
                            <span className="bestCouponAmount">Get @ <span style={{ fontWeight: "bold", color: "#1DA1F2" }}>₹{displayPrice}</span> only!</span>
                            <span className="bestPercentageOff">{discountPercentOnMrp.toFixed(0)}% Off</span>
                          </Typography>
                        );
                      })()
                    ) : coupon?.discountAmount ? (
                      (() => {
                        const discountedPrice = product?.offerPrice - coupon?.discountAmount;
                        const discountPercent = (((product?.mrp - discountedPrice) / product?.mrp) * 100);
                        if (!discountedPrice || isNaN(discountedPrice)) return null;
                        const fraction = discountedPrice - Math.floor(discountedPrice);
                        const displayPrice = fraction >= 0.1 ? discountedPrice.toFixed(2) : discountedPrice.toFixed(0);

                        return (
                          <Typography className="bestCouponTag">
                            <span className="bestCouponAmount">Get @ <span style={{ fontWeight: "bold", color: "#1DA1F2" }}>₹{displayPrice}</span> only!</span>
                            <span className="bestPercentageOff">{discountPercent.toFixed(0)}% Off</span>
                          </Typography>
                        );
                      })()
                    ) : coupon?.discountPrice ? (
                      (() => {
                        const discountedPrice = coupon?.discountPrice;
                        const discountPercent = (((product?.mrp - discountedPrice) / product?.mrp) * 100);
                        if (!discountedPrice || isNaN(discountedPrice)) return null;
                        const fraction = discountedPrice - Math.floor(discountedPrice);
                        const displayPrice = fraction >= 0.1 ? discountedPrice.toFixed(2) : discountedPrice.toFixed(0);
                        return (
                          <Typography className="bestCouponTag">
                            <span className="bestCouponAmount">Get @ <span style={{ fontWeight: "bold", color: "#1DA1F2" }}>₹{displayPrice}</span> only!</span>
                            <span className="bestPercentageOff">{discountPercent.toFixed(0)}% Off</span>
                          </Typography>
                        );
                      })()
                    ) : null}
                  </Box>
                  <Typography className="bestCouponCode">with <span style={{ color: '#0E4961', fontWeight: 'bold' }}>"{coupon?.couponCode}"</span> Coupon!</Typography>
                </>
              ) : (
                <>
                  {quantityInCart > 0 ? (
                    <Box className="addedBtn" onClick={() => handleProductClick(product)}>
                      Added
                      <Box className="addedNumber">
                        {quantityInCart}
                      </Box>
                    </Box>
                  ) : (
                    <Box className={product?.isExhausted ? "exhaustedBtn" : "notAddedBtn"} onClick={!product?.isExhausted ? () => handleProductClick(product) : null}>
                      {product?.isExhausted ?
                        <>
                          <Typography className="exhaustedText">Out Of Stock</Typography>
                        </> :
                        <>
                          <Box sx={{ display: 'flex' }}>
                            <Typography>Add</Typography>
                            <KeyboardArrowRight className="arrowBtn" />
                          </Box>
                        </>
                      }
                    </Box>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          {/* <Grid item xs={12} className="bestCoupon">
            {coupon && (
              <>
                <Box className="bestCouponBox">
                  {coupon?.discountPercent ? (
                    (() => {
                      const discountAmount = (coupon?.discountPercent / 100) * product?.offerPrice;
                      const discountedPrice = product?.offerPrice - discountAmount;
                      if (!discountedPrice || isNaN(discountedPrice)) return null;
                      const fraction = discountedPrice - Math.floor(discountedPrice);
                      const displayPrice =
                        fraction >= 0.1 ? discountedPrice.toFixed(2) : discountedPrice.toFixed(0);
                      const discountPercentOnMrp =
                        ((product?.mrp - discountedPrice) / product?.mrp) * 100;

                      return (
                        <Typography className="bestCouponTag">
                          Get @ <span style={{ fontWeight: "bold", color: "#1DA1F2" }}>₹{displayPrice}</span> only! Get <span style={{ fontWeight: "bold", color: "#1DA1F2" }}>{discountPercentOnMrp.toFixed(0)}% OFF</span> on MRP
                        </Typography>
                      );
                    })()
                  ) : coupon?.discountAmount ? (
                    (() => {
                      const discountedPrice = product?.offerPrice - coupon?.discountAmount;
                      const discountPercent = (((product?.mrp - discountedPrice) / product?.mrp) * 100);
                      if (!discountedPrice || isNaN(discountedPrice)) return null;
                      const fraction = discountedPrice - Math.floor(discountedPrice);
                      const displayPrice = fraction >= 0.1 ? discountedPrice.toFixed(2) : discountedPrice.toFixed(0);

                      return (
                        <Typography className="bestCouponTag">
                          Get @ <span style={{ fontWeight: 'bold', color: '#1DA1F2' }}>₹{displayPrice}</span> only! Get <span style={{ fontWeight: 'bold', color: '#1DA1F2' }}>{discountPercent.toFixed(0)}% OFF</span> on MRP
                        </Typography>
                      );
                    })()
                  ) : coupon?.discountPrice ? (
                    (() => {
                      const discountedPrice = coupon?.discountPrice;
                      const discountPercent = (((product?.mrp - discountedPrice) / product?.mrp) * 100);
                      if (!discountedPrice || isNaN(discountedPrice)) return null;
                      const fraction = discountedPrice - Math.floor(discountedPrice);
                      const displayPrice = fraction >= 0.1 ? discountedPrice.toFixed(2) : discountedPrice.toFixed(0);
                      return (
                        <Typography className="bestCouponTag">
                          Get @ <span style={{ fontWeight: 'bold', color: '#1DA1F2' }}>₹{displayPrice}</span> only! Get <span style={{ fontWeight: 'bold', color: '#1DA1F2' }}>{discountPercent.toFixed(0)}% OFF</span> on MRP
                        </Typography>
                      );
                    })()
                  ) : null}
                </Box>
                <Typography className="bestCouponCode">with <span style={{ color: '#0E4961', fontWeight: 'bold' }}>"{coupon?.couponCode}"</span> Coupon!</Typography>
              </>
            )}
          </Grid> */}
        </Grid>
      </Hidden>
      <Hidden smDown>
        <Grid container className="productBox" justifyContent={'center'}>
          <Grid item md={12}>
            <Box className="imageBox" onClick={() => handleProductClick(product)}>
              <img className="image" onClick={() => handleProductClick(product)} src={product?.displayImageUrl} alt="Product" onError={(e) => { e.target.onerror = null; e.target.src = Images.xpanseNavLogo; }} />
            </Box>
          </Grid>
          <Grid item md={12}>
            <Typography className="productName">{product?.name}</Typography>
            <Stack flexDirection={'row'} alignItems={'center'}>
              <Typography className="productPrice">
                <span className="productOfferprice">₹{product?.offerPrice}</span>
                {product?.mrp > product?.offerPrice && (
                  <span className="productMrp">₹{product?.mrp}</span>
                )}
              </Typography>
              <>
                {product?.foodType === 'VEG/VEGAN' ? (
                  <Stack direction={'row'} alignItems={'center'}>
                    <img className='veg-nonveg' src={Images.veg} alt='veg' />
                    <img style={{ height: '17px', marginRight: '4px', marginLeft: '0px' }} className='veg-nonveg' src={Images.vegan} alt='vegan' />
                  </Stack>
                ) : (
                  <img className='veg-nonveg' src={product?.foodType === 'VEG' ? Images.veg : (product?.foodType === 'VEGAN' ? Images.vegan : Images.nonVeg)} alt={product?.foodType === 'VEG' ? 'veg' : (product?.foodType === 'VEGAN' ? 'vegan' : 'non-veg')} />
                )}
              </>
            </Stack>
          </Grid>
          <Grid item xs={12} className="bestCoupon">
            {coupon ? (
              <>
                <Box className="bestCouponBox">
                  {coupon?.discountPercent ? (
                    (() => {
                      const discountAmount = (coupon?.discountPercent / 100) * product?.offerPrice;
                      const discountedPrice = product?.offerPrice - discountAmount;
                      if (!discountedPrice || isNaN(discountedPrice)) return null;
                      const fraction = discountedPrice - Math.floor(discountedPrice);
                      const displayPrice =
                        fraction >= 0.1 ? discountedPrice.toFixed(2) : discountedPrice.toFixed(0);
                      const discountPercentOnMrp =
                        ((product?.mrp - discountedPrice) / product?.mrp) * 100;

                      return (
                        <Typography className="bestCouponTag">
                          <span className="bestCouponAmount">Get @ <span style={{ fontWeight: "bold", color: "#1DA1F2" }}>₹{displayPrice}</span> only!</span>
                          <span className="bestPercentageOff">{discountPercentOnMrp.toFixed(0)}% Off</span>
                        </Typography>
                      );
                    })()
                  ) : coupon?.discountAmount ? (
                    (() => {
                      const discountedPrice = product?.offerPrice - coupon?.discountAmount;
                      const discountPercent = (((product?.mrp - discountedPrice) / product?.mrp) * 100);
                      if (!discountedPrice || isNaN(discountedPrice)) return null;
                      const fraction = discountedPrice - Math.floor(discountedPrice);
                      const displayPrice = fraction >= 0.1 ? discountedPrice.toFixed(2) : discountedPrice.toFixed(0);

                      return (
                        <Typography className="bestCouponTag">
                          <span className="bestCouponAmount">Get @ <span style={{ fontWeight: "bold", color: "#1DA1F2" }}>₹{displayPrice}</span> only!</span>
                          <span className="bestPercentageOff">{discountPercent.toFixed(0)}% Off</span>
                        </Typography>
                      );
                    })()
                  ) : coupon?.discountPrice ? (
                    (() => {
                      const discountedPrice = coupon?.discountPrice;
                      const discountPercent = (((product?.mrp - discountedPrice) / product?.mrp) * 100);
                      if (!discountedPrice || isNaN(discountedPrice)) return null;
                      const fraction = discountedPrice - Math.floor(discountedPrice);
                      const displayPrice = fraction >= 0.1 ? discountedPrice.toFixed(2) : discountedPrice.toFixed(0);
                      return (
                        <Typography className="bestCouponTag">
                          <span className="bestCouponAmount">Get @ <span style={{ fontWeight: "bold", color: "#1DA1F2" }}>₹{displayPrice}</span> only!</span>
                          <span className="bestPercentageOff">{discountPercent.toFixed(0)}% Off</span>
                        </Typography>
                      );
                    })()
                  ) : null}
                </Box>
                <Typography className="bestCouponCode">with <span style={{ color: '#0E4961', fontWeight: 'bold' }}>"{coupon?.couponCode}"</span> Coupon!</Typography>
              </>
            ) : (
              token && coupon && <Box className="emptyBox" />
            )}
          </Grid>
          <Grid item md={12} display={'flex'} justifyContent="center" alignItems="center">
            <>
              {quantityInCart > 0 ? (
                <Box className="addedBtn" onClick={() => handleProductClick(product)}>
                  Added
                  <Box className="addedNumber">
                    {quantityInCart}
                  </Box>
                </Box>
              ) : (
                <Box className={product?.isExhausted ? "exhaustedBtn" : "notAddedBtn"} onClick={!product?.isExhausted ? () => handleProductClick(product) : null}>
                  {product?.isExhausted ?
                    <>
                      <Typography className="exhaustedText">Out Of Stock</Typography>
                    </> :
                    <>
                      <Box sx={{ display: 'flex' }}>
                        <Typography>ADD</Typography>
                        <KeyboardArrowRight className="arrowBtn" />
                      </Box>
                    </>
                  }
                </Box>
              )}
            </>
          </Grid>
        </Grid>
      </Hidden>
    </Box>
  );
};

export default ProductCard;

{/* {product?.offerPercentage > 0 && (
                  <Typography className="productOff">
                    {product?.offerPercentage?.toFixed(0)}% OFF
                  </Typography>
                )} */}