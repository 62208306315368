import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Box, InputAdornment } from '@mui/material';
import SearchSharp from '@mui/icons-material/SearchSharp';
import { GOOGLE_MAP } from '../utils/Api';

function PlacesAutocomplete({ onCoordinatesChange }) {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const radius = 100;

  // Default location for suggestions
  // const defaultLocation = "12.931316595874005,77.61649243443775";

  useEffect(() => {
    if (inputValue.length < 3) {
      setOptions([]);
      return;
    }

    const fetchSuggestions = async () => {
      try {
        const url = `https://api.olamaps.io/places/v1/autocomplete?input=${encodeURIComponent(inputValue)}&radius=${encodeURIComponent(radius)}&strictbounds=true&api_key=${GOOGLE_MAP.OLA_MAP_KEY}`;
        const response = await fetch(url);
        const data = await response.json();
        setOptions(data.predictions || []);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    };
    const debounceTimer = setTimeout(() => {
      fetchSuggestions();
    }, 300);
    return () => clearTimeout(debounceTimer);
  }, [inputValue]);

  // When a suggestion is selected, pass its coordinates via the callback.
  const handleOptionSelect = (event, value) => {
    if (value && value.geometry && value.geometry.location) {
      const lat = value.geometry.location.lat;
      const lng = value.geometry.location.lng;
      onCoordinatesChange({ lat, lng });
      console.log(`Selected coordinates: Latitude: ${lat}, Longitude: ${lng}`);
    }
  };

  return (
    <Autocomplete
      freeSolo
      options={options}
      getOptionLabel={(option) => option.description || ""}
      onChange={handleOptionSelect}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Enter location"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          sx={{ backgroundColor: '#fff' }}
          InputLabelProps={{ style: { color: '#2196F3' } }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Box sx={{ display: 'flex', color: '#1DA1F2' }}>
                  <SearchSharp />
                </Box>
              </InputAdornment>
            ),
            style: { borderColor: '#2196F3' },
          }}
        />
      )}
    />
  );
}

export default PlacesAutocomplete;
