import React from 'react';
import { Box, Typography } from '@mui/material';

function PrivacyPolicy() {
  return (
    <div className='privacyScreen'>
        <Typography className='privacyTitle'>PRIVACY POLICY</Typography>
        <Box className='privacyPolicyContainer'>
            <div>
                <Typography className='lastUpdatedText'>LAST UPDATED: 20-09-2024</Typography>
                <p className='privacyPolicyDesc'>In these terms, references to "you," "yourself," "your" shall refer to the user of the Xpanse app as defined herein. The terms "we," "us," "our," "Company" shall refer to TEAM24 RETAIL TECH PRIVATE LIMITED, with its registered address at House No. 13/279/A70 T-11, Shed No Silvio Heights, Panaji, Tiswadi, North Goa - 403001, Goa, which holds the ownership rights to Xpanse, the brand, and the Xpanse Mobile App, the official food ordering app of Xpanse Café.</p>
                <p className='privacyPolicyDesc'>Xpanse (“Xpanse” or “we”) takes the privacy of your information seriously. This Privacy Notice describes the types of personal information we collect from you through our stores, website (including sub-domains and microsites) and mobile applications. It also describes the purposes for which we collect that personal information, the other parties with whom we may share it and the measures we take to protect the security of your data. It also tells you about your rights and choices with respect to your personal information, and how you can contact us about our privacy practices. 
                    <br/><br/>You are advised to carefully read this Privacy Notice before using or availing any of our products and/or services.
                </p>
            </div>
            <div>
                <Typography className='aboutUsText'>DEFINITIONS</Typography>
                <p className='privacyPolicyDesc'>In this Privacy Notice, the following definitions are used:</p>
                <p className='privacyPolicyDesc'>Cookies: A small file placed on your device by our website or mobile application when you either visit or use certain features of our website or mobile application. A cookie generally allows a website to remember your actions or preference for a certain period of time.</p>
                <p className='privacyPolicyDesc'>Data: Includes non-personal information, personal information and sensitive personal information about you, which either directly or indirectly in combination with other information, could allow you to be identified when you visit our stores, website and/or mobile application.</p>
                <p className='privacyPolicyDesc'>Data Protection Laws: Any applicable law for the time being in force relating to the processing of Data.</p>
                <p className='privacyPolicyDesc'>Partners: Select third parties with whom we have contracts for the businesses described in this Privacy Notice.</p>
                <p className='privacyPolicyDesc'>Service Providers: Includes entities to whom we or others will disclose your Data in order to process information for a specific purpose pursuant to a written contract.</p>
                <p className='privacyPolicyDesc'>Xpanse: A company incorporated in India whose registered office is at House No. 13/279/A70, T 11, Silvio Heights, St. Inez, Tiswadi Panaji, 403001, Goa.</p>
                <p className='privacyPolicyDesc'>User or you: The natural person who accesses our stores, website or mobile application.</p>
            </div>
            <div>
                <Typography className='purposeText'>WHAT DATA DO WE COLLECT ABOUT YOU</Typography>    
                <p className='privacyPolicyDesc'>
                    Xpanse collects Data for various purposes set out in this Privacy Notice.
                    This Data includes, without limitation, the following categories:
                    <ul>
                        (a) Contact information: first and last name, email address, postal address, country, employer, phone number and other similar contact data. 
                        <br/>(b) Financial information: payment instrument information, transactions, transaction history, preferences, method, mode and manner of payment, spending pattern or trends, and other similar data. 
                        <br/>(c) Technical information: website, device and mobile app usage, Internet Protocol (IP) address and similar information collected via automated means, such as cookies, pixels and similar technologies.
                        <br/>(d) Transaction information: the date of the transaction, total amount, transaction history and preferences and related details.
                        <br/>(e) Product and service information: Your account membership number, registration and payment information, and program-specific information, when you request products and/or services directly from us, or participate in marketing programs.
                        <br/>(f) Personal information: Age, sex, date of birth, or any other personal information provided in responses to surveys or questionnaires.
                        <br/>(g) Your reviews, feedback and opinions about our products, programmes and services.
                        <br/>(h) Loyalty programme information: your loyalty membership information, account details, profile or password details and any frequent flyer or travel partner programme affiliation.
                    </ul>
                </p>
            </div>
            <div>
                <Typography className='consentText'>HOW WE COLLECT DATA</Typography>
                <p className='privacyPolicyDesc'>
                    We collect Data in the following ways:
                    <ul>
                        ●	Information You Give Us: We receive and store any information you enter on our website or mobile application or give us in any other way (e.g., at outlets, stores, hotels, kiosks). Please see the section titled “Data Shared by You” for more information.
                        <br/>●	Automatic Information We Collect: We use “cookies”, pixels and similar technologies to receive and store certain types of information whenever you interact with us. Please see the section below, titled “Data that is Collected Automatically” for more information.
                        <br/>●	E-mail Communications: To help us make e-mails more relevant and interesting, we often receive a confirmation (if your device supports such capabilities) when you open e-mail from us or click on a link in the e-mail. You can choose not to receive marketing emails from us by clicking on the unsubscribe link in any marketing email.
                        <br/>●	Automatic Information We Collect from Other Websites:  We receive and store certain types of information when you interact with third-party websites that use our technology or with whom we have a specific agreement.  Because we process this information on behalf of the applicable website operators, collection, processing, and use of such information is subject to the applicable website operators’ privacy policies and is not covered by our Privacy Notice. 
                        <br/>●	Information from Other Sources:  We may obtain information from other sources. An example of this is when you authorize a third-party, to interact directly with our website or mobile application to provide or receive Data about you. In that case, we might receive such Data used by that third-party website to identify your account with that website.
                        <br/>●	Information Previously Provided: Where you have shared any information previously with any of the further sharing of such information, such information will be shared with us.
                    </ul>
                    You can make choices about our collection and use of your Data. For example, you may want to access, edit or remove your Data on our website or mobile application. When you are asked to provide Data, you may decline. 
                </p>
            </div>
            <div>
                <Typography className='consentText'>DATA SHARED BY YOU</Typography>
                <p className='privacyPolicyDesc'>Xpanse may collect your Data in several ways from your use of our stores, website or mobile application. For instance:
                    <ul>
                        (a) When you register with us to receive our products and/or services;
                        <br/>(b) When you conduct a transaction with us or attempt a transaction at our stores, on our website or mobile application;
                        <br/>(c) When you complete surveys conducted by or for us;
                        <br/>(d) When you elect to receive any communications (including promotional offers) from us;
                        <br/>(e) From the information gathered by your visit to our stores, website or mobile application;
                    </ul>
                </p>
            </div>
            <div>
                <Typography className='consentText'>DATA THAT IS COLLECTED AUTOMATICALLY</Typography>
                <p className='privacyPolicyDesc'>
                    <ul>
                        (a) We automatically collect some information when you visit our website or use our mobile application. This information helps us to make improvements to our content and navigation. The information collected automatically includes your IP address.
                        <br/>(b) Our web servers or affiliates who provide analytics and performance enhancement services collect IP addresses, operating system details, browsing details, device details and language settings. This information is aggregated to measure the number of visits, average time spent on the site, pages viewed and similar information. Xpanse uses this information to measure the site usage, improve content and to ensure safety and security, as well enhance performance of our website or mobile application.
                        <br/>(c) We may collect your Data automatically via Cookies, pixels and similar technologies in line with settings on your browser. For more information about Cookies, please see the section below, titled “Cookies, Pixels and Similar Technologies”.
                    </ul>
                </p>
            </div>
            <div>
                <Typography className='consentText'>OUR USE OF DATA </Typography>
                <p className='privacyPolicyDesc'>
                    Any or all the above Data may be required by us from time to time to provide information relating to Xpanse and to work on the experience when using our website or mobile application. Specifically, Data may be used by us for the following reasons:
                    <ul>
                        (a) Carry out our obligations arising from any contract entered into between you and us;
                        <br/>(b) Provide products and/or services and communicate with you about products and/or services offered by us;
                        <br/>(c) Enable Partners to offer their products and/or services and communicate with you about such products and/or services;
                        <br/>(d) Processing, disclosing, transmitting, and/or sharing the data/information with other third parties which have business or contractual dealings with us;
                        <br/>(e) Provide you with offers (including for financial products and/or services), personalized services and recommendations and improve your experience on our website and mobile application;
                        <br/>(f) Operate, evaluate and improve our business, website and mobile application;
                        <br/>(g) Generate aggregated data to prepare insights to enable us to understand customer behaviour, patterns and trends with a view to learning more about your preferences or other characteristics; 
                        <br/>(h) Provide privileges and benefits to you, marketing and promotional campaigns based on your profile;  
                        <br/>(i) In connection with loyalty programs owned and operated by us
                        <br/>(j) Communicate with you (including to respond to your requests, questions, feedback, claims or
                        disputes) and to customize and improve our services;
                        <br/>(k) Enforce the terms of use of our website and mobile application;
                        <br/>(l) Protect against and prevent fraud, illegal activity, harm, financial loss and other legal or information security risks; and
                        <br/>(m) serve other purposes for which we provide specific notice at the time of collection, and as otherwise authorized or required by applicable law.
                    </ul>
                    We treat these inferences as personal information (or sensitive personal information, as the case may be), where required under applicable law. Some of the above grounds for processing will overlap and there may be several grounds which justify our use of your personal information.
                    Where required under applicable law, we will only use your personal information with your consent; as necessary to provide you with products and/or services; to comply with a legal obligation; or when there is a legitimate interest that necessitates the use.
                </p>
            </div>
            <div>
                <Typography className='consentText'>MINORS</Typography>
                <p className='privacyPolicyDesc'>Our website and mobile application do not offer products or services for use by minors. If you are under 18, you may use our website or mobile application only with the involvement of a parent or guardian.</p>
            </div>
            <div>
                <Typography className='consentText'>SHARING OF DATA</Typography>
                <p className='privacyPolicyDesc'>
                    We may share your Data with/ for:
                    <ul>
                        (a) Partners: We may make available to you services, products, or applications provided by Partners for use on or through our website or mobile application. If you choose to use such service, customer information related to those transactions may be shared with such Partner.  
                        Such Partners will be required to respect the security of your Data and to treat it in accordance with this privacy policy and applicable law. Such Partners’ use your Data for the purposes of providing their own products or services to you. Their use of your Data is subject to their own privacy policies. For further information on how these Partners process your Data, please refer to their privacy policies.
                        <br/>(b) We may make available to you products, services and /or applications to assist them to reach out to you in relation to their programs or campaigns and to process your queries and requests. Accordingly, we may share your Data. We may also share your Data with the relevant for the purposes set out in Clause 7 above, and to facilitate the operation of our business. Their use of your Data is subject to their own privacy policies. For further information on how they process your Data, please review their privacy policies. 
                        <br/>(c) Consumer Platform: Your Data may be shared with other participating entities on the Consumer Platform operated by the purposes of enrolment, offering you products, services and benefits on the XPANSE. Accordingly, we may share your Data with other Partners and Service Providers. Their use of your Data is subject to their own privacy policy. For further information on how Partners and Service Providers process your Data for the purpose of enrolment, offering products, services and benefits on XPANSE, please review their privacy policies.
                        <br/>(d) Service Providers:  We may share your Data with Service Providers. Examples include storing and analysing Data, protecting and securing our systems, providing search results and links, providing customer service, credit analysis, processing your information for profiling, user analysis and payment processing. 
                        These Service Providers will be required to only process Data in accordance with express instructions and as necessary to perform services for purposes set forth in this Privacy Notice. The Service Providers will also be required to safeguard the security and confidentiality of the Data they process by implementing appropriate technical and organizational security measures and confidentiality obligations binding employees accessing Data.
                        <br/>(e) When Xpanse acts as a Service Provider: We may process and share your Data Partners when we act as a service provider to such Partners.
                        <br/>(f) Protecting Xpanse: We may release Data when we believe release is appropriate to comply with applicable law or legal process, enforce or apply the Terms of Use of our website or mobile application and other agreements, protect Xpanse against harm or financial loss, when we believe disclosure is necessary to protect individuals’ vital interests, or in connection with an investigation of suspected or actual fraudulent or illegal activity. This may include exchanging information with other companies and organizations for fraud protection, risk management and dispute resolution.  This does not include selling or otherwise disclosing personally identifiable information from users for commercial purposes in violation of this Privacy Notice.
                        Business Transfers: As we continue to develop our business, we might sell or buy subsidiaries or business units. Your Data (including in relation to loyalty programs) may be transferred as part of such transaction. 
                        <br/>(g) Third Parties: We may also share your Data with other third parties where:
                            <ul>
                                1. You request or authorize us to do so;
                                <br/>2. We need to comply with applicable law or respond to valid legal process; or
                                <br/>3. We need to operate and maintain the security of our website or mobile application, including to prevent or stop an attack on our computer systems or networks.
                            </ul>
                    </ul>
                    We require these third parties by contract to only process sensitive personal data in accordance with our instructions and as necessary to perform services on our behalf or in compliance with applicable law. We also require them to safeguard the security and confidentiality of the sensitive personal data they process on our behalf by implementing appropriate confidentiality, technical and organizational security measures.
                    Please note that Partners may have privacy practices that differ from those of Xpanse.  The use of your Data will be governed by their privacy statements when you provide Data on their websites.
                </p>
            </div>
            <div>
                <Typography className='consentText'>KEEPING DATA SECURE</Typography>
                <p className='privacyPolicyDesc'>We will use technical and organisational measures to safeguard your Data and we store your Data on secure servers.  Technical and organisational measures include measures to deal with any suspected data breach.  If you suspect any misuse or loss or unauthorised access to your Data, please let us know immediately by contacting us by e-mail.</p>
            </div>
            <div>
                <Typography className='consentText'>RETENTION OF DATA</Typography>
                <p className='privacyPolicyDesc'>Xpanse retains Data for as long as necessary for the use of our products and/or services or to provide access to and use of our website or mobile application, or for other essential purposes such as complying with our legal obligations, resolving disputes, enforcing our agreements and as long as processing and retaining your Data is necessary for our legitimate interests. Because these needs can vary for different data types and purposes, actual retention periods can vary significantly.
                    <br/><br/>Even if we delete your Data, including on account of exercise of your right under Clause 12 below, it may persist on backup or archival media for audit, legal, tax or regulatory purposes.
                </p>
            </div>
            <div>
                <Typography className='consentText'>YOUR RIGHTS AND CHOICES</Typography>
                <p className='privacyPolicyDesc'>
                    When we process Data about you, we do so with your consent and/or as necessary to operate our business, meet our contractual and legal obligations, protect the security of our systems and our customers, or fulfil other legitimate interests of Xpanse as described in this Privacy Notice.
                    <br/><br/>We may transfer Data we collect about you to recipients in India, where we are headquartered.  India may not have the same Data Protection Laws as the country in which you initially provided the information. When we transfer your Data to India, we will protect that information as described in this Privacy Notice, as disclosed to you at the time of data collection or as described in our program-specific privacy notice.
                    <br/><br/>Depending on which Data Protection Laws are applicable to you, you may have the right or choice to:
                    <ul>
                        ●	Opt out of some collection or uses of your Data, including the use of cookies, pixels and similar technologies and the use of your Data for marketing purposes.
                        <br/>●	Access your Data, rectify it, restrict or object to its processing, or request its deletion or anonymization.
                        <br/>●	Change or edit information submitted to us.
                        <br/>●	Receive the Data you provided to us to transmit it to another company.
                        <br/>●	Withdraw any consent provided or alter your preferences.
                        <br/>●	Where applicable, lodge a complaint with your supervisory authority.
                    </ul>
                    You may submit a request as described in the “How to Contact Us” section below. We will not charge you for any request. Where we are legally permitted to do so, we may refuse your request.  If we refuse your request, we will tell you the reasons why.
                    <br/><br/>Below, you will find additional privacy information that you may find important. Data Protection Laws, depending on your country, may include the following rights in relation to your Data:
                    <ul>
                        1.	a) Right to Confirmation and Access - the right to confirm our methods of processing and request
                            <ul>
                                (i)  copies of the information we hold about you at any time, or <br/>
                                (ii)  that we modify, or update such information.        
                            </ul>
                        <br/>2.	b) Right to Correction - the right to have your Data rectified if it is inaccurate or incomplete, upon providing supporting documentation.
                        <br/>3.	c) Right to Restrict / Object to Our Use of your Data - the right to limit the way in which we can use it.
                        <br/>4.	d) Right to Withdraw Consent – the right to withdraw your consent provided earlier.
                        <br/>5.	e) Right to File Complaints – the right to raise complaints to a regulatory authority. 
                    </ul>
                    For information about managing your Data and promotional communications, please e-mail us at customercare
                    <br/><br/>It is important that the Data we hold about you is accurate and current. Please keep us informed if your personal information changes during the period for which we hold it.
                </p>
            </div>
            <div>
                <Typography className='consentText'>WHERE WE STORE DATA</Typography>
                <p className='privacyPolicyDesc'>Data collected under this Privacy Notice is hosted on servers located in India. </p>
            </div>
            <div>
                <Typography className='consentText'>PROCESSING YOUR DATA</Typography>
                <p className='privacyPolicyDesc'>We take steps to ensure that the Data we collect under this Privacy Notice is processed according to the provisions of this Privacy Notice and the requirements of applicable law.
                    <br/><br/>To ensure that your Data receives an adequate level of protection, we have put in place appropriate written contracts with Partners and Service Providers that we share your Data with.   This ensures your Data is treated by such parties in a way that is consistent with applicable law.
                </p>
            </div>
            <div>
                <Typography className='consentText'>ACCOUNT DELETION</Typography>
                <p className='privacyPolicyDesc'>
                    User can delete his account and all the account information on this given URL <a target='_blank' href='https://xpansecafe.com/deleteAccount'>Delete Your Account - https://xpansecafe.com/deleteAccount</a>
                </p>
            </div>
            <div>
                <Typography className='consentText'>COOKIES, PIXELS AND SIMILAR TECHNOLOGIES</Typography>
                <p className='privacyPolicyDesc'>Our website and mobile application may place and access certain Cookies on your device. Cookies are unique identifiers that we transfer to your device to enable our systems to recognize your device.  We also use pixels and similar technologies to analyse traffic on our website and mobile application to improve your experience of using them.</p>
            </div>
            <div>
                <Typography className='consentText'>SEVERABILITY</Typography>
                <p className='privacyPolicyDesc'>If any court or competent authority finds that any provision of this Privacy Notice (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision will, to the extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of this Privacy Notice will not be affected.</p>
            </div>
            <div>
                <Typography className='consentText'>CHANGES TO THIS PRIVACY NOTICE</Typography>
                <p className='privacyPolicyDesc'>Our business changes constantly and our Privacy Notice will change also. We may e-mail periodic reminders of our notices and conditions, unless you have instructed us not to, but you should check our website and mobile application frequently to see recent changes. The updated version will be effective as soon as it is accessible. Any changes will be immediately posted on our website and mobile application and you are deemed to have accepted the terms of the updated Privacy Notice on your first use of our website or mobile application or first purchase of the products and/or services following the alterations. We encourage you to review this Privacy Notice frequently to be informed of how we are protecting your information.</p>
            </div>
            <div>
                <Typography className='consentText'>HOW TO CONTACT US</Typography>
                <p className='privacyPolicyDesc'>To request to review, update, or delete your personal information or to otherwise reach us, please submit a request by e-mailing us at contact@xpansecafe.com. You may contact us for information on Service Providers and Partners with whom we may share your Data in compliance with this Privacy Notice and applicable law. We will respond to your request within 30 days.</p>
            </div>
        </Box>
    </div>
  );
}

export default PrivacyPolicy;
