import { Box, Typography, TextField, Grid, InputAdornment, Drawer, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import PrimaryButton from '../../components/PrimaryButton';
import axios from 'axios';
import { API } from '../../utils/Api';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/Profile/UserSlice';
import { ArrowBack, GetApp, Language } from '@mui/icons-material';
import gsap from 'gsap';
import Images from '../../utils/Images';
import toast, { Toaster } from 'react-hot-toast';

function MobileNumber() {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [number, setNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [countdown, setCountdown] = useState(59);
  const [errors, setErrors] = useState({});
  const [showOtpField, setShowOtpField] = useState(false);
  const [isGetOtpButtonClicked, setIsGetOtpButtonClicked] = useState(false);
  const [changeNumberClicked, setChangeNumberClicked] = useState(false); 
  const [authType, setAuthType] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleDownloadApp = () => {
    // window.open('https://your-app-link.com', '_blank');
    // window.location.href = "https://r8rpc.test-app.link/";
    navigate('/QRForwardScreen');
    setDrawerOpen(false);
  };

  const handleContinueToWebsite = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    let interval;
    if (isGetOtpButtonClicked && countdown > 0) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [countdown, isGetOtpButtonClicked]);

  // useEffect(() => {
  //   const elements = ['.cup-text', '.authenticationScreen .numberField', '.authenticationScreen .buttonContainer', '.termsConditions', '.progressBox'];
  //   gsap.from(elements[0], { x: '120%', duration: 0.8, ease: 'power3.out', onComplete: animateBack });
  //   function animateBack() {
  //     gsap.to(elements, { x: '0', duration: 0.8, ease: 'power3.inOut', stagger: 0.2 });
  //   }
  //   gsap.from(elements[1], { x: '120%', duration: 0.8, ease: 'power3.out', delay: 0.3 });
  //   gsap.from(elements[2], { x: '120%', duration: 0.8, ease: 'power3.out', delay: 0.6 });
  //   gsap.from(elements[3], { x: '120%', duration: 0.8, ease: 'power3.out', delay: 0.9 });
  //   gsap.from(elements[4], { x: '120%', duration: 0.8, ease: 'power3.out', delay: 0.12 });
  // }, []);

  useEffect(() => {
    const elements = ['.cup-text', '.authenticationScreen .numberField', '.authenticationScreen .buttonContainer', '.termsConditions', '.progressBox' ];
    gsap.from(elements, { x: '120%', duration: 0.8, ease: 'power3.out' });
    gsap.to(elements, { x: '0', duration: 0.8, ease: 'power3.inOut' });
  }, []);

  const schema = Yup.object().shape({
    number: Yup.string().matches(/^[6789][0-9]{9}$/, 'Must be a 10 digit number starting with 6, 7, 8, or 9').required('Mobile Number is required'),
  });

  const validateField = async (field, value) => {
    try {
      await schema.validateAt(field, { number: value });
      setErrors({ ...errors, [field]: '' });
      if (field === 'number' && value.length === 10) {
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setErrors({ ...errors, [field]: error.message });
      }
    }
  };

  const handleResendOtp = async () => {
    try {
      const response = await axios.post(`${API.BASE_URL}auth/user/send-otp`, {
        "mobile": number,
      });
      if (response.data.status === true) {
        toast.success('OTP resent successfully');
      }
    } catch (error) {
      // console.error('Error:', error.response.data.data.message);
      let errorMessage = error?.response?.data?.data?.message || "Try again after sometime.";
      toast.error(errorMessage);
    } finally {
    }
  };

  const handleInputChange = (field, value) => {
    if (field === 'number' && !isGetOtpButtonClicked ) {
      setNumber(value);
      validateField('number', value);
    } else if (field === 'otp') {
      setOtp(value);
      setErrors({ ...errors, [field]: '' });
    }
  };

  const handleButtonClick = async () => {
    const isValid = await schema.isValid({ number });
    // console.log(number);
    // setIsGetOtpButtonClicked(true);
    // setBtnDisabled(true);
    if (isValid) {
      try {
        await schema.validate({ number }, { abortEarly: false });
        setIsGetOtpButtonClicked(true);
        setBtnDisabled(true);
        const response = await axios.post(`${API.BASE_URL}auth/user/send-otp`, {
          "mobile": number,
        });
        if (response?.data?.status === true) {
          // console.log('OTP sent successfully:', response.data);
          toast.success('OTP sent successfully!');
          setShowOtpField(true);
          setCountdown(59);
        }
        setAuthType(response?.data?.data?.authType);
        setBtnDisabled(false);
      } catch (error) {
        // console.error('Error:', error);
        let errorMessage = error?.response?.data?.data?.message || "Try again after sometime.";
        toast.error(errorMessage);
        setBtnDisabled(false);
      } finally {
        setBtnDisabled(false);
      }
    }
  };
  
  const handleOtpButtonClick = () => {
    if (authType === 'LOGIN') {
      setBtnDisabled(true);
      axios.post(`${API.BASE_URL}auth/user/verify-otp`, {
        "mobile": number,
        // "mobile": 9591258909,
        "otp": otp,
        "isSignup": false
      })
      .then(response => {
        // console.log('Login API Response:', response.data);
        navigate('/advertiseScreen');
        localStorage.setItem('token', response?.data?.data?.token);
        localStorage.setItem('userDataLogin', JSON.stringify(response?.data?.data?.user));
        localStorage.setItem('userNameHeader', response?.data?.data?.user?.name);
        if (response?.data?.data?.user?.profileImage !== null) {
          localStorage.setItem('userProfileImage', response?.data?.data?.user?.profileImage);
        }
        dispatch(setUser(response?.data?.data?.user));
        toast.success('Logged in successfully!');
        setBtnDisabled(false);
      })
      .catch(error => {
        // console.error('Error:', error.response.data.data.message);
        let errorMessage = error?.response?.data?.data?.message || "Try again after sometime.";
        toast.error(errorMessage);
        setBtnDisabled(false);
      });
    }
    else {
      setBtnDisabled(true);
      axios.post(`${API.BASE_URL}auth/user/verify-otp`, {
        "mobile": number,
        "otp": otp,
        "isSignup": true
      })
      .then(response => {
        // console.log('Login API Response:', response?.data);
        toast.success('OTP verified successfully!');
        gsap.to('.mainBox', { x: '-100%', duration: 0.5, onComplete: () => {
          navigate('/accountDetails', { state: { mobile: number } });
        }});
        setBtnDisabled(false);
      })
      .catch(error => {
        // console.error('Error:', error.response.data.data.message);
        let errorMessage = error?.response?.data?.data?.message || "Try again after sometime.";
        toast.error(errorMessage);
        setBtnDisabled(false);
      });
    }
  };

  const handleChangeNumberClick = () => {
    setChangeNumberClicked(true);
    setShowOtpField(false);
    setIsGetOtpButtonClicked(false);
    setCountdown(0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);    
  }, []);

  return (
    <>
      <Box className="authenticationScreen" sx={{ overflow: 'hidden'}}>
        { !isGetOtpButtonClicked && 
          <Typography className='skipText' onClick={() => navigate('/advertiseScreen')}>Skip</Typography>
        }
        <Grid className='mainBox' item md={3} sx={{ padding: '10px', margin: '0px' }}>
          { !isGetOtpButtonClicked && 
            <Typography className="cup-text" sx={{ fontWeight: '600', fontSize: '30px', margin: '21px 0 31px 0', lineHeight: '1.2' }}>
              Lets Get You a Cup
            </Typography>
          }
          { isGetOtpButtonClicked &&
            <Box className="step-away-text">
              <Typography className='changeNumberText' onClick={handleChangeNumberClick} >Change Number</Typography>
              {/* <ArrowBack sx={{ margin: '16px 0 0 5px', fontSize: '30px' }} onClick={handleChangeNumberClick} /> */}
              <Typography sx={{ fontWeight: '600', fontSize: '30px', margin: '21px 0 31px 0', lineHeight: '1.2' }}>
                Your cup is one <br /> Step away
              </Typography>
            </Box>
          }
          <Box sx={{ textAlign: 'left' }}>
            <Box className="numberField">
              <TextField
                fullWidth
                id="number"
                sx={{
                  border: '3px solid #1E9CED',
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#1DA1F2',
                    },
                    '&:hover fieldset': {
                      borderColor: 'cornflowerblue',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1DA1F2',
                    },
                  },
                }}
                disabled={isGetOtpButtonClicked}
                placeholder="10 digit mobile number"
                variant="outlined"
                value={number}
                onChange={(e) => handleInputChange('number', e.target.value)}
                onBlur={() => validateField('number', number)}
                InputLabelProps={{ style: { color: '#2196F3' } }}
                error={!!errors.number}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box sx={{ display: 'flex', borderRight: '1px solid #474747', paddingRight: '8px' }}>
                        <span>+91</span>
                      </Box>
                    </InputAdornment>
                  ),
                  style: { borderColor: '#2196F3' },
                }}
              />
              {errors.number && (
                <Typography className="errorText" color="error">
                  {errors.number}
                </Typography>
              )}
            </Box>
            <Box>
              <TextField
                fullWidth
                id="otp"
                className={`otpField ${showOtpField ? 'showOtpField' : ''}`}
                sx={{
                  border: '3px solid #1E9CED',
                  borderRadius: '8px',
                  marginTop: '10px',
                  backgroundColor: '#fff',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#1DA1F2',
                    },
                    '&:hover fieldset': {
                      borderColor: 'cornflowerblue',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1DA1F2',
                    },
                  },
                }}
                placeholder="Enter OTP"
                variant="outlined"
                value={otp}
                onChange={(e) => handleInputChange('otp', e.target.value)}
                onBlur={() => validateField('otp', otp)}
                inputProps={{ style: { textAlign: 'center' } }}
                InputLabelProps={{ style: { color: '#2196F3' } }}
                error={!!errors.otp}
              />
              {errors.otp && (
                <Typography className="errorText" color="error">
                  {errors.otp}
                </Typography>
              )}
            </Box>
          </Box>
          <Grid container justifyContent={'center'} style={{ margin: '27px 0 0px 0' }}>
            <Grid className="buttonContainer" item xs={12} sm={12} md={12} style={{ top: showOtpField ? '0px' : '-60px' }}>
              <PrimaryButton
                title={showOtpField ? 'Proceed' : 'Get OTP'}
                style={{ borderRadius: '30px', fontSize: '58px' }}
                onClick={() => {
                  if (!showOtpField) {
                    handleButtonClick();
                  } else {
                    handleOtpButtonClick();
                    console.log('Proceed clicked');
                  }
                }}
                spinner={true}
                disabledTitle={'Please Wait'}
                disabled={btnDisabled}
              />
            </Grid>
          </Grid>
          { isGetOtpButtonClicked &&
            <Typography  className="resendOtp-text" style={{ fontSize: '16px', textAlign: 'left', margin: '20px 0 10px 0',  height: showOtpField ? '50px' : '0px'  }}>
              {countdown <= 0 ? (
                  <span
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                      handleResendOtp();
                      setCountdown(59);
                    }}
                  >
                    Resend OTP
                  </span>
                  ) : (
                  <span>
                    Resend OTP in
                  <span
                  style={{
                    textDecoration: 'underline',
                    color: '#fff',
                    cursor: 'pointer',
                    marginLeft: '5px',
                  }}
                  >
                    {countdown < 10 ? `00:0${countdown}` : `00:${countdown}`}
                  </span>
                  </span>
              )}
            </Typography>
          }
          { !isGetOtpButtonClicked &&
            <Typography className='termsConditions' style={{ color: '#000', fontSize: '13px' }}>
              By clicking, I accept the <span onClick={() => window.open('/termsAndConditions', '_blank')} style={{ textDecoration: 'underline', color: '#fff' }}>terms of service</span> &{' '}
              <span onClick={() => window.open('/privacyPolicy', '_blank')}  style={{ textDecoration: 'underline', color: '#fff' }}>privacy policy</span>
            </Typography>
          }
          { !isGetOtpButtonClicked &&
            <Box className="progressBox">
              <img className='progressDots img-fluid' src={Images.firstScreenDots} alt='ProgressDots' />      
            </Box>
          }
        </Grid>
      </Box>
      <Drawer anchor="bottom" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            padding: '30px 20px',
            backgroundColor: '#DFEEF4',
            borderRadius: '20px 20px 0 0',
            boxShadow: '0px -4px 20px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: '#0E4961',
              fontWeight: 'bold',
              marginBottom: '20px',
              fontSize: '18px',
              textTransform: 'uppercase',
              letterSpacing: '1px',
            }}
          >
            Enhance Your Experience<br/> With Our App
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                backgroundColor: '#09A5F7',
                color: '#fff',
                borderRadius: '50px',
                padding: '15px 30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '80%',
                boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.2s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
                cursor: 'pointer',
              }}
              onClick={handleDownloadApp}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Download App</Typography>
              {/* <GetApp sx={{ marginLeft: '16px', color: '#fff' }} /> */}
              <img style={{ marginLeft: '16px', height: '30px' }} alt='Xpanse-Logo' src={Images.xpanseNavLogo} />
            </Box>

            <Box
              sx={{
                backgroundColor: '#D0EFFF',
                color: '#0E4961',
                borderRadius: '50px',
                padding: '15px 30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '80%',
                boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.2s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
                cursor: 'pointer',
              }}
              onClick={handleContinueToWebsite}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Continue to Website</Typography>
              <Language sx={{ marginLeft: '16px', color: '#0E4961' }} />
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

export default MobileNumber;