import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid, Typography, Dialog, Button, Hidden, useTheme, useMediaQuery, Modal } from '@mui/material';
import Images from '../../utils/Images';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../../store/Products/productsSlice';
import axios from 'axios';
import { API } from '../../utils/Api';
import StoreHeader from '../../components/StoreHeader';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { setData } from '../../store/Cart/cartSlice';
import ArrivingNowOrders from '../../components/ArrivingNowOrders';
import ProductListingSkeleton from '../../components/SkeletonComponents/ProductListingSkeleton';
import AuthModals from '../../components/AuthComponents/AuthModals';
import ProductDrawer from '../../components/ProductDrawer';
import { Player } from '@lottiefiles/react-lottie-player';
import SortByComponent from '../../components/SortByComponent';
import ProductCard from '../../components/ProductCard';
import { useSocket } from '../../context/SocketContext';
import glowingBg from '../../assets/animations/glowingBg.gif';

const ProductsListing = () => {
  const [showBox, setShowBox] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const dispatch = useDispatch();
  const socket = useSocket();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(categories[0]?._id);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const outlet = localStorage.getItem("selectedStoreData");
  const deliveryType = localStorage.getItem("selectedOrderType");
  const token = localStorage.getItem("token");
  const outletObject = JSON.parse(outlet);
  const outletId = outletObject?._id;
  const [isFavorited, setIsFavorited] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [cartData, setCartData] = useState([]);
  const navigate = useNavigate();
  const cartDataRedux = useSelector(state => state?.cart?.data);
  const searchText = useSelector(state => state.search.searchText);
  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('selectedStoreData')));
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const theme = useTheme();
  const matchesMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [selectedAddons, setSelectedAddons] = useState({});
  const [addingToCart, setAddingToCart] = useState(false);
  const [fetchingProducts, setFetchingProducts] = useState(true);
  const matchesMobile = useMediaQuery(theme.breakpoints.up('md'));
  const [activeFilter, setActiveFilter] = useState(null);
  const [popAdvertisement, setPopAdvertisement] = useState(false);
  const [randomAd, setRandomAd] = useState([]);
  const containerRef = useRef(null);

  // const getPopupAdvertisment = async () => {
  //   if (!token || !outletData?._id || !deliveryType ) {
  //     return;
  //   }
  //   try {
  //     const response = await axios.get(`${API.BASE_URL}in-app-advertisement/getRandomAd`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     console.log('Random Ads:', response?.data?.data);
  //     setRandomAd(response?.data?.data?.advertisement);
  //   } catch (error) {
  //     console.log('Random Ads Error', error?.response?.data);
  //   }
  // };

  // useEffect(() => {
  //   if (!token || !outletData?._id || !deliveryType ) {
  //     return;
  //   }
  //   const delay = 15000;
  //   const hours = 1 * 60 * 60 * 1000;
  //   // const hours = 30000;
  //   const timer = setTimeout(() => {
  //     const lastPopup = localStorage.getItem('lastPopupTimestamp');
  //     const now = Date.now();
  //     if (!lastPopup || now - Number(lastPopup) > hours) {
  //       getPopupAdvertisment();
  //       setPopAdvertisement(true);
  //       localStorage.setItem('lastPopupTimestamp', now);
  //     }
  //   }, delay);
  //   return () => clearTimeout(timer);
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCategoryClick = (categoryId, index) => {
    setActiveCategory(categoryId);
    dispatch(fetchProducts(categoryId));
    console.log("Products after dispatching fetchProducts:", products);
    scrollToIndex(index);
  };

  useEffect(() => {
    fetchUserCartData();
    const shimmerTimeout = setTimeout(() => {
      setShowBox(true);
    }, 3000);
    return () => clearTimeout(shimmerTimeout);
  }, []);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    console.log('Product', product);
  };

  const handleAdvertismentProductClick = (randomAd) => {
    setSelectedProduct(randomAd?.product);
    setPopAdvertisement(false);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setQuantity(1);
    setSelectedAddons({});
  };

  const handleIncreaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  useEffect(() => {
    if (selectedProduct?.hasAddon && selectedProduct?.addons && selectedProduct?.addons?.length > 0) {
      const defaultAddons = {};
      selectedProduct?.addons?.forEach((addon) => {
        const defaultAddonValue = addon?.addonValues?.find((value) => value?.isDefault);
        if (defaultAddonValue) {
          defaultAddons[addon._id] = defaultAddonValue;
        }
      });
      setSelectedAddons(defaultAddons);
    }
  }, [selectedProduct]);

  const handleAddonSelect = (addonId, addonValue, isMultipleSelect = false) => {
    setSelectedAddons((prevSelectedAddons) => {
      if (isMultipleSelect) {
        // Handle multiple selections
        const currentSelections = Array.isArray(prevSelectedAddons[addonId])
          ? prevSelectedAddons[addonId]
          : [];

        const isAlreadySelected = currentSelections.some(
          (selected) => selected._id === addonValue._id
        );

        const updatedSelections = isAlreadySelected
          ? currentSelections.filter((selected) => selected._id !== addonValue._id) : [...currentSelections, addonValue];

        console.log('updatedSelections', updatedSelections);

        return {
          ...prevSelectedAddons,
          [addonId]: updatedSelections,
        };
      } else {
        const isSelected = prevSelectedAddons[addonId]?._id === addonValue._id;
        const isDefault = addonValue.isDefault;

        if (isSelected && !isDefault) {
          const updatedAddons = { ...prevSelectedAddons };
          delete updatedAddons[addonId];
          return updatedAddons;
        }

        return {
          ...prevSelectedAddons,
          [addonId]: addonValue,
        };
      }
    });
  };

  const handleAddToCart = async () => {
    if (token) {
      setAddingToCart(true);
      try {
        const addonsArray = Object.keys(selectedAddons).map((addonId) => {
          const selectedAddon = selectedAddons[addonId];
          if (Array.isArray(selectedAddon)) {
            // Handle multiple selections
            return selectedAddon.map((addonValue) => ({
              _id: addonId,
              selectedValue: addonValue.value,
              addonValueId: addonValue._id,
              // Add any other fields from addonValue as required
            }));
          } else {
            // Handle single selection
            return {
              _id: addonId,
              selectedValue: selectedAddon.value,
              addonValueId: selectedAddon._id,
              // Add any other fields from selectedAddon as required
            };
          }
        });

        // Flatten the addonsArray to ensure a single array of objects
        const flattenedAddonsArray = addonsArray.flat();

        const cartItem = {
          ordersType: deliveryType,
          outlet: outletId,
          cartItems: [
            {
              product: selectedProduct._id,
              quantity: quantity,
              addons: flattenedAddonsArray.length > 0 ? flattenedAddonsArray : undefined,
            }
          ],
        };

        const response = await axios.post(`${API.BASE_URL}cart`, cartItem, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        handleCloseModal();
        fetchUserCartData();
        setAddingToCart(false);
        toast.success('Product added to cart!!!');
        console.log(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setAddingToCart(false);
      }
    } else {
      if (matchesMdUp) {
        handleOpenAuth();
      } else {
        navigate('/mobileNumber');
      }
    }
  };

  const fetchUserCartData = async () => {
    if (!token || !outletData?._id) {
      return;
    }
    try {
      const response = await axios.get(`${API.BASE_URL}cart/userCart/${outletData._id}/${deliveryType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setData(response?.data));
      setCartData(cartDataRedux);
      // console.log('cartDataRedux', cartDataRedux);
      // console.log('Cart Screen Data', response?.data?.data?.cartItems);
    } catch (error) {
      dispatch(setData([]));
      console.log('Cart Screen Error', error?.response?.data);
    }
  };

  // const getCategoriesList = async () => {
  //   setFetchingProducts(true);
  //   if (!outletData?._id) {
  //     return;
  //   }
  //   try {
  //     const response = await axios.get(`${API.BASE_URL}masters/category/getCategoryWithProducts/${outletData._id}`, {
  //       headers: token
  //         ? {
  //           Authorization: `Bearer ${token}`,
  //         }
  //         : {},
  //       params: {
  //         search: searchText,
  //         foodType: activeFilter
  //       }
  //     });
  //     let fetchedCategories = response?.data?.data?.categories;

  //     // Sort categories if isEventOutlet is true
  //     if (outletData?.isEventOutlet) {
  //       fetchedCategories = fetchedCategories.sort((a, b) => {
  //         return (b.isEventCategory ? 1 : 0) - (a.isEventCategory ? 1 : 0);
  //       });
  //     }
  //     setCategories(response?.data?.data?.categories);
  //     setFetchingProducts(false);
  //     // console.log('Outlet data', outletData.isEventOutlet);
  //     // setProducts(response.data.data.products);
  //     // console.log('Categories fetched successfully in the screen:', response?.data?.data?.categories);
  //   } catch (error) {
  //     console.error('Error:', error);
  //   } finally {
  //     setFetchingProducts(false);
  //   }
  // };

  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (document.visibilityState === 'visible') {
  //       getCategoriesList();
  //     }
  //   };
  //   document.addEventListener('visibilitychange', handleVisibilityChange);
  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   };
  // }, []);

  useEffect(() => {
    getCategoriesList();
  }, [searchText, activeFilter]);

  useEffect(() => {
    fetchUserCartData();
    // getCategoriesList();
    // getProductsList(activeCategory);
  }, []);

  const getProductQuantityInCart = (productId) => {
    let totalQuantity = 0;
    cartDataRedux?.data?.cartItems?.forEach(item => { if (item.product._id === productId) { totalQuantity += item.quantity; } });
    return totalQuantity;
  };

  const scrollToIndex = (index) => {
    const container = containerRef.current;
    const element = container.childNodes[index];
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const [totalPrice, setTotalPrice] = useState(selectedProduct?.offerPrice || 0);
  const [totalMrpPrice, setTotalMrpPrice] = useState(selectedProduct?.mrp || 0);
  const [offerPercentage, setOfferPercentage] = useState(0);

  useEffect(() => {
    let calculateTotalPrice = () => {
      let price = selectedProduct?.offerPrice ?? 0;
      let mrp = selectedProduct?.mrp ?? 0;
      Object.values(selectedAddons).forEach(addon => {
        price += addon.offerPrice ?? 0;
        mrp += addon.mrp ?? 0;
      });
      setTotalPrice(price);
      setTotalMrpPrice(mrp);
    };
    calculateTotalPrice();
  }, [selectedAddons, selectedProduct]);

  useEffect(() => {
    if (totalMrpPrice > 0 && totalPrice < totalMrpPrice) {
      const discount = ((totalMrpPrice - totalPrice) / totalMrpPrice) * 100;
      setOfferPercentage(discount);
    } else {
      setOfferPercentage(0);
    }
  }, [totalPrice, totalMrpPrice]);

  const params = useParams();
  const referralId = params['referId'];

  useEffect(() => {
    if (referralId) {
      localStorage.setItem('referralId', referralId);
      // console.log("Referral ID stored in localStorage:", referralId);
    }
  }, [referralId]);

  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenAuth = () => {
    setModalOpen(true);
  }

  const handleCloseAuth = () => {
    setModalOpen(false);
  }

  const handleFilterChange = (value) => {
    setActiveFilter(value);
  };

  const categoriesCacheRef = useRef({ query: null, data: null });

  const getCategoriesList = async () => {
    if (!outletData?._id) return;
    const cacheKey = `categoriesCache_${outletData._id}_${searchText}_${activeFilter || 'all'}`;

    // Try to retrieve cached data from sessionStorage
    const cachedItem = sessionStorage.getItem(cacheKey);
    if (cachedItem) {
      try {
        const parsedCache = JSON.parse(cachedItem);
        setCategories(parsedCache);
        setFetchingProducts(false);
        console.log("Using cached categories from sessionStorage");
        return;
      } catch (error) {
        console.error("Error parsing cached data:", error);
      }
    }

    setFetchingProducts(true);
    try {
      const response = await axios.get(
        `${API.BASE_URL}masters/category/getCategoryWithProducts/${outletData._id}`,
        {
          headers: token ? { Authorization: `Bearer ${token}` } : {},
          params: {
            search: searchText,
            foodType: activeFilter
          }
        }
      );
      let fetchedCategories = response?.data?.data?.categories;
      if (outletData?.isEventOutlet) {
        fetchedCategories = fetchedCategories.sort((a, b) => {
          return (b.isEventCategory ? 1 : 0) - (a.isEventCategory ? 1 : 0);
        });
      }
      setCategories(fetchedCategories);
      // Store the fetched data in sessionStorage (only for the current session)
      sessionStorage.setItem(cacheKey, JSON.stringify(fetchedCategories));
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setFetchingProducts(false);
    }
  };

  // This function is similar but is triggered by socket events to force update
  const getCategoriesListSocket = async () => {
    if (!outletData?._id) return;
    const cacheKey = `categoriesCache_${outletData._id}_${searchText}_${activeFilter || 'all'}`;

    try {
      const response = await axios.get(`${API.BASE_URL}masters/category/getCategoryWithProducts/${outletData._id}`, {
        headers: token ? { Authorization: `Bearer ${token}` } : {},
      });
      let fetchedCategories = response?.data?.data?.categories;
      if (outletData?.isEventOutlet) {
        fetchedCategories = fetchedCategories.sort((a, b) => {
          return (b.isEventCategory ? 1 : 0) - (a.isEventCategory ? 1 : 0);
        });
      }
      setCategories(fetchedCategories);
      categoriesCacheRef.current = { query: JSON.stringify({ searchText, activeFilter, outletId: outletData._id }), data: fetchedCategories };
      sessionStorage.setItem(cacheKey, JSON.stringify(fetchedCategories));
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Instead of calling getCategoriesList repeatedly, call it only when needed.
  useEffect(() => {
    let timeoutId;
    const checkDataAvailability = () => {
      if (outletData) {
        setIsDataAvailable(true);
        clearTimeout(timeoutId);
        getCategoriesList();
      } else {
        timeoutId = setTimeout(checkDataAvailability, 500);
        setOutletData(JSON.parse(localStorage.getItem('selectedStoreData')));
      }
    };
    checkDataAvailability();
    return () => clearTimeout(timeoutId);
  }, [outletData]);

  useEffect(() => {
    if (!socket || !outletId) return;
    console.log('Connected to socket server');

    const events = [
      { name: 'newProductAdded', message: 'New Product Added' },
      { name: 'productUpdated', message: 'Product Updated' },
      { name: 'productDeleted', message: 'Product Deleted' },
      { name: 'productOutOfStock', message: 'Product Out Of Stock' },
      { name: 'productRestocked', message: 'Product Restocked' },
      { name: 'newCategoryAdded', message: 'New Category Added' },
      { name: 'categoryUpdated', message: 'Category Updated' },
      { name: 'categoryDeleted', message: 'Category Deleted' },
      { name: 'categoryDisplayImageUpdated', message: 'Category Display Image Updated' },
      { name: 'newCouponAdd', message: 'New Coupon Added' },
      { name: 'couponUpdate', message: 'Coupon Updated' },
      { name: 'couponDelete', message: 'Coupon Deleted' },
      { name: 'productAddonAdd', message: 'Product addon added' },
      { name: 'productAddonUpdate', message: 'Product addon updated' },
      { name: 'productAddonDelete', message: 'Product addon deleted', },
      { name: 'productAddonItemAdd', message: 'Product Addon item added' },
      { name: 'productAddonItemDelete', message: 'Product addon item deleted' },
      { name: 'productAddonIconAdd', message: 'Product addon Icon added' }
    ];

    events.forEach(({ name, message }) => {
      socket.on(name, () => {
        setTimeout(getCategoriesListSocket, 1000);
        console.log(message);
      });
    });

    return () => {
      events.forEach(({ name }) => socket.off(name));
    };
  }, [socket, outletId, getCategoriesListSocket]);

  useEffect(() => {
    getCategoriesListSocket();
  }, [token]);

  return (
    <>
      <Hidden mdUp>
        <StoreHeader />
        {/* <SearchBar /> */}
        <ArrivingNowOrders />
        <SortByComponent activeFilter={activeFilter} onFilterChange={handleFilterChange} />
      </Hidden>
      <Box>
        {fetchingProducts ? (
          <ProductListingSkeleton />
        ) : categories?.filter(category =>
          outletData?.isEventOutlet || !category?.isEventCategory
        ).every(category =>
          category.products.filter(product => outletData?.isEventOutlet || !product?.isEventProduct).filter(product => product?.status !== 'INACTIVE').length === 0
        ) ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
            <Player autoplay loop={true} keepLastFrame={true} src={Images.noResult} style={{ height: '350px' }} />
            <Typography variant='h6'>Sorry, didn't find what you are looking for...</Typography>
          </Box>
        ) : (
          <React.Fragment>
            <Box className="products-listing">
              <Box className="category-list">
                {categories?.slice().sort((a, b) => {
                  if (outletData.isEventOutlet) {
                    return (b.isEventCategory ? 1 : 0) - (a.isEventCategory ? 1 : 0) || a.sequenceNumber - b.sequenceNumber;
                  }
                  return a.sequenceNumber - b.sequenceNumber;
                }).filter(category => outletData.isEventOutlet || !category?.isEventCategory).map((category, index) => (
                  <Box className="d-flex category-items-content" key={category._id}>
                    <Box className={`category-item-highlight-line ${activeCategory === category._id || (index === 0 && !activeCategory) ? 'active' : ''}`}></Box>
                    <Box
                      className={`category-item ${activeCategory === category._id || (index === 0 && !activeCategory) ? 'active' : ''}`}
                      onClick={() => handleCategoryClick(category._id, index)}
                    >
                      {category?.name}
                    </Box>
                  </Box>
                ))}
              </Box>
              <Box className="products-container">
                <Grid ref={containerRef} container>
                  {categories?.map((category, index) => {
                    let totalProductsRendered = 0;
                    const totalProducts = category?.products?.length;
                    let productsPerRow = 3;
                    const showCategoryTopImage = outletData?.isEventOutlet || (!outletData?.isEventOutlet && !category?.isEventCategory);
                    return (
                      <Grid container key={category?._id}>
                        {showCategoryTopImage && (
                          <Grid md={12}>
                            <img className="categoryTopImage" src={category?.bannerImageUrl} alt={category?.name} />
                          </Grid>
                        )}
                        {category?.products.slice().filter(product => outletData?.isEventOutlet || !product?.isEventProduct).filter(product => product?.status !== 'INACTIVE')
                          .sort((a, b) => {
                            // Push exhausted products to the end
                            if (a.isExhausted && !b.isExhausted) return 1;
                            if (!a.isExhausted && b.isExhausted) return -1;
                            // Otherwise sort by sequence number
                            return a.sequenceNumber - b.sequenceNumber;
                          })
                          .map((product, productIndex) => {
                            const rowPosition = Math.floor(productIndex / productsPerRow);
                            const colPosition = productIndex % productsPerRow;
                            const isLastRow = (Math.ceil(totalProducts / productsPerRow) - 1) === rowPosition;
                            const isLastInRow = colPosition === (productsPerRow - 1) || productIndex === (totalProducts - 1);
                            const isFirstInRow = colPosition === 0;
                            return (
                              <>
                                <Grid item sm={12} md={4} key={product?._id}>
                                  <ProductCard
                                    product={product}
                                    // isFavorited={isProductInWishlist(product?._id)}
                                    quantityInCart={getProductQuantityInCart(product?._id)}
                                    handleProductClick={handleProductClick}
                                    matchesMdUp={matchesMdUp}
                                    isLastInRow={colPosition === productsPerRow - 1 || productIndex === totalProducts - 1}
                                    isLastRow={Math.ceil(totalProducts / productsPerRow) - 1 === rowPosition}
                                    coupon={product?.bestCoupon}
                                  />
                                </Grid>
                              </>);
                          })}
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>
              <ProductDrawer
                open={!!selectedProduct}
                onClose={handleCloseModal}
                selectedProduct={selectedProduct}
                handleAddToCart={handleAddToCart}
                quantity={quantity}
                handleIncreaseQuantity={handleIncreaseQuantity}
                handleDecreaseQuantity={handleDecreaseQuantity}
                selectedAddons={selectedAddons}
                handleAddonSelect={handleAddonSelect}
                totalPrice={totalPrice}
                totalMrpPrice={totalMrpPrice}
                offerPercentage={offerPercentage}
                addingToCart={addingToCart}
              />
              <Dialog open={loginModalOpen} onClose={() => setLoginModalOpen(false)}>
                <Box p={3}>
                  <Typography variant="h5">Login Required</Typography>
                  <Typography>Please login to use this feature.</Typography>
                  <Button onClick={() => setLoginModalOpen(false)}>Close</Button>
                  <Hidden mdUp>
                    <Button onClick={() => navigate('/mobileNumber')}>Login Now</Button>
                  </Hidden>
                </Box>
              </Dialog>
            </Box>

            {/* <Box className="product-listing-curve">
              <Box className="product-listing-curve-inside-box"></Box>
            </Box> */}
            <Box className="product-listing-line"></Box>
          </React.Fragment>
        )}
      </Box>
      <AuthModals openAuth={isModalOpen} handleCloseAuth={handleCloseAuth} />
      <Modal
        open={popAdvertisement}
        onClose={() => setPopAdvertisement(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: 'none',
          outline: 'none',
          backgroundImage: `url(${glowingBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Box
          component="img"
          onClick={() => {
            handleAdvertismentProductClick(randomAd);
            setPopAdvertisement(false);
          }}
          src={randomAd.advertisementImage}
          alt="Advertisement Image"
          sx={{
            width: {
              xs: '75%',
              sm: '65%',
              md: '25%',
            },
            border: 'none',
            outline: 'none',
            cursor: 'pointer'
          }}
        />
      </Modal>
    </>
  );
};

export default ProductsListing;